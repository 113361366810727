export const bootStrapGrid = { sm: 768, md: 992, lg: 1200 };

export const initialSearchParams = {
  trademarkWord: "",
  applicationNumber: "",
  registrationNumber: "",
  dossierStatus: "",
  trademarkKind: "",
  applicantName: "",
  representativeName: "",
  businessID: "",
  applicationStartDate: "",
  applicationEndDate: "",
  oppositionPeriodStartStartDate: "",
  oppositionPeriodStartEndDate: "",
  registrationStartDate: "",
  registrationEndDate: "",
  expirationStartDate: "",
  expirationEndDate: "",
  goodsAndServicesTerm: "",
  goodsAndServicesClassNumber: "",
  viennaClass: "",
  filingNumber: "",
  basicSearch: "",
};

export const typeFilters = [
  "Word",
  "Figurative",
  "Figurative-word",
  "3D",
  "3D-word",
  "Colour",
  "Position",
  "Pattern",
  "Hologram",
  "Sound",
  "Motion",
  "Multimedia",
  "Other",
];

export const validStatusFilters = [
  "Application_filed",
  "Appeal_pending",
  "Registered_wait_publication",
  "Registered_opposition_period_running",
  "Registered",
  "Registration_opposed",
  "Rev_inv_pending",
  "IR_pending_in_Finland",
  "IR_appeal_pending",
  "IR_accepted_as_valid_in_Finland_waiting_for_publication",
  "IR_accepted_as_valid_in_Finland_period_for_filing_an_opposition_running",
  "IR_valid_in_Finland",
  "IR_accepted_as_valid_in_Finland_opposition_proceedings_under_way",
  "IR_rev_inv_pending",
];

export const nonvalidStatusFilters = [
  "Application_withdrawn",
  "Application_dismissed",
  "Application_rejected",
  "Registration_revoked_after_opposition",
  "Registration_cancelled_court_decision",
  "Registration_revoked",
  "Registration_invalidated",
  "Registration_revoked_and_invalidated",
  "Ended_withdrawn",
  "Ended",
  "Expired",
  "Application_rejected_due_to_opposition",
  "IR_not_valid_in_Finland_refused",
  "IR_canceled",
  "IR_rejected",
  "IR_not_valid_in_Finland_rejected_on_account_of_an_opposition",
  "IR_removed_from_registration_on_account_of_court_order",
  "IR_not_valid_in_Finland_revoked",
  "IR_not_valid_in_Finland_invalidated",
  "IR_not_valid_in_Finland_revoked_and_invalidated",
  "IR_not_valid_in_Finland_dismissed",
  "IR_not_valid_in_Finland_not_renewed",
];

export const statusFilters = [
  "Application_filed",
  "Registered_wait_publication",
  "Registered_opposition_period_running",
  "Registration_opposed",
  "Registered",
  "Expired",
  "Application_withdrawn",
  "Application_dismissed",
  "Application_rejected",
  "Appeal_pending",
  "Rev_inv_pending",
  "Registration_revoked_after_opposition",
  "Registration_cancelled_court_decision",
  "Registration_revoked",
  "Registration_invalidated",
  "Registration_revoked_and_invalidated",
  "Ended_withdrawn",
  "Ended",
  "Application_rejected_due_to_opposition",
  "IR_pending_in_Finland",
  "IR_accepted_as_valid_in_Finland_waiting_for_publication",
  "IR_accepted_as_valid_in_Finland_period_for_filing_an_opposition_running",
  "IR_accepted_as_valid_in_Finland_opposition_proceedings_under_way",
  "IR_valid_in_Finland",
  "IR_not_valid_in_Finland_not_renewed",
  "IR_not_valid_in_Finland_refused",
  "IR_canceled",
  "IR_rejected",
  "IR_appeal_pending",
  "IR_rev_inv_pending",
  "IR_not_valid_in_Finland_rejected_on_account_of_an_opposition",
  "IR_removed_from_registration_on_account_of_court_order",
  "IR_not_valid_in_Finland_revoked",
  "IR_not_valid_in_Finland_invalidated",
  "IR_not_valid_in_Finland_revoked_and_invalidated",
  "IR_not_valid_in_Finland_dismissed",
];

export default false;
