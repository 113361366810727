import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import Icons from "../../../scss/prh-icon-sprite.svg";

const FilterField = (props) => {
  const { translate } = useContext(I18nContext);
  return (
    <Row
      className="m-0 pt-1 pb-2"
      style={{
        backgroundColor: props.selected ? "#ebebeb" : "white",
      }}
    >
      <Col className="" xs={10}>
        <button
          onClick={props.handleClick}
          value={props.value}
          data-cy={props.value}
          className={props.selected ? "select-btn-selected" : "select-btn"}
          aria-pressed={props.selected}
        >
          {props.text}
        </button>
      </Col>
      <Col xs={2}>
        <button
          onClick={props.handleClick}
          value={props.value}
          className={props.selected ? "select-btn-selected" : "select-btn"}
        >
          {props.selected ? (
            <svg
              alt={translate("trademark_filtering_selected")}
              className="sort-chevron-icon p-0"
            >
              <use xlinkHref={`${Icons}#check`}></use>)
            </svg>
          ) : null}
        </button>
      </Col>
    </Row>
  );
};

export default FilterField;
