import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { I18nContext } from "../../i18n";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";

const propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
};

const defaultProps = {
  initialPage: 1,
  pageSize: 20,
};

const Pagination = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  const [pager, setPager] = useState({});
  useEffect(() => {
    setPage(props.currentPage);
  }, [props.items]);

  const setPage = (page) => {
    let { items, pageSize } = props;

    if (page < 1 || page > pager.totalPages || !items) {
      return;
    }

    const newPager = getPager(items.length, page, pageSize);
    let pageOfItems = items.slice(newPager.startIndex, newPager.endIndex + 1);
    setPager(newPager);
    props.onChangePage(page, pageOfItems);
  };

  const getPager = (totalItems, currentPage, pageSize) => {
    currentPage = currentPage || 1;
    pageSize = pageSize || 20;
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage, endPage;
    const pagesOnBar = props.pagesOnBar;

    if (totalPages <= pagesOnBar) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= pagesOnBar / 2) {
        startPage = 1;
        endPage = pagesOnBar;
      } else if (currentPage + Math.floor(pagesOnBar / 2) >= totalPages) {
        startPage = totalPages - pagesOnBar + 1;
        endPage = totalPages;
      } else {
        startPage =
          currentPage + 1 - Math.floor(pagesOnBar / 2) - (pagesOnBar % 2);
        endPage = currentPage + Math.floor(pagesOnBar / 2);
      }
    }

    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    let pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  };

  if (!pager.pages || pager.pages.length <= 1) {
    return null;
  }

  return (
    <nav
      aria-label={translate("trademark_navigation_pagination")}
      className="mt-3"
      aria-current={pager.currentPage}
    >
      <ul
        role="menubar"
        aria-disabled="false"
        aria-label={translate("trademark_navigation_pagination")}
        className="pagination pagination-md"
        id="page-numbers"
      >
        {size.width < bootStrapGrid.sm ? null : (
          <span className="prh-pagination__page-count">
            {translate("trademark_navigation_pagination_pages")}{" "}
            {pager.startPage} - {pager.endPage} / {pager.totalPages}
          </span>
        )}
        <li
          className={
            pager.currentPage === 1 ? "page-item disabled" : "page-item"
          }
        >
          <button
            role="menuitem"
            aria-label={translate("trademark_navigation_pagination_first_page")}
            className="page-link"
            onClick={() => setPage(1)}
          >
            ‹‹
          </button>
        </li>
        <li
          className={
            pager.currentPage === 1 ? "page-item disabled" : "page-item"
          }
        >
          <button
            role="menuitem"
            aria-label={translate(
              "trademark_navigation_pagination_previous_page"
            )}
            className="page-link"
            onClick={() => setPage(pager.currentPage - 1)}
          >
            ‹
          </button>
        </li>
        {pager.pages.map((page, index) => (
          <li
            key={index}
            className={
              pager.currentPage === page ? "page-item active" : "page-item"
            }
          >
            <button
              aria-label={
                translate("trademark_navigation_pagination_page_number") + page
              }
              aria-pressed={pager.currentPage === page}
              className="page-link"
              onClick={() => setPage(page)}
            >
              {page}
            </button>
          </li>
        ))}
        <li
          className={
            pager.currentPage === pager.totalPages
              ? "page-item disabled"
              : "page-item"
          }
        >
          <button
            role="menuitem"
            aria-label={translate("trademark_navigation_pagination_next_page")}
            className="page-link"
            onClick={() => setPage(pager.currentPage + 1)}
          >
            ›
          </button>
        </li>
        <li
          className={
            pager.currentPage === pager.totalPages
              ? "page-item disabled"
              : "page-item"
          }
        >
          <button
            role="menuitem"
            aria-label={translate("trademark_navigation_pagination_last_page")}
            className="page-link"
            onClick={() => setPage(pager.totalPages)}
          >
            ››
          </button>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;
