import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import { formatTrademarkPath } from "../../helpers/helpers";
import { Link, withRouter } from "react-router-dom";

const OppDetailedInfo = (props) => {
  const { translate } = useContext(I18nContext);
  const fields = props.data;

  const targetedTrademarks = fields.relatedTrademarks.map((item) => {
    return (
      <Col key={item.ordinal} xs={4} md={3}>
        <Link
          className="p-0"
          to={{
            pathname: props.pathParams.buildPath( "trademark/" + formatTrademarkPath(item) )
          }}
        >
          {item.registrationNumber}
        </Link>
      </Col>
    );
  });

  const oppositionGrounds = (
    <div className="pb-2">
      <h3>{translate("trademark_opposition_grounds")}</h3>
      {fields.oppositionGrounds < 1 ? (
        <span>-</span>
      ) : (
        <dl>
          <Row>
            <Col xs={4}>
              <dt>{translate("trademark_opposition_ground")}</dt>
            </Col>
            <Col xs={4}>
              <dt>{translate("trademark.portlet.register.number")}</dt>
            </Col>
            <Col xs={4}>
              <dt>{translate("trademark_opposition_country")}</dt>
            </Col>
          </Row>
          {fields.oppositionGrounds.map((item) => (
            <Row key={item.ordinal}>
              <Col xs={4}>
                <dd className="datafield-content">
                  {translate(
                    "trademark_opposition_groundtype_" +
                      item.groundType.toLowerCase()
                  ) || "-"}
                </dd>
              </Col>
              <Col xs={4}>
                <dd className="datafield-content">
                  {item.registrationNumber || "-"}
                </dd>
              </Col>
              <Col xs={4}>
                <dd className="datafield-content">
                  {item.registrationCountry || "-"}
                </dd>
              </Col>
            </Row>
          ))}
        </dl>
      )}
    </div>
  );

  return (
    <div>
      <h2>{translate("trademark_detailed_info")}</h2>
      <h3>{translate("targeted_trademark")}</h3>
      <Row className="mb-4">{targetedTrademarks}</Row>
      {oppositionGrounds}
    </div>
  );
};

export default withRouter(OppDetailedInfo);
