import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import TmrBasicInfo from "./infoComponents/TmrBasicInfo";
import TmrDetailedInfo from "./infoComponents/TmrDetailedInfo";
import TargetGroupInfo from "./infoComponents/TargetGroupInfo";
import PersonInfo from "../common/PersonInfo";
import CorrespondenceInfo from "../common/CorrespondenceInfo";
import GoodsAndServicesInfo from "../TrademarkDetails/infoComponents/TmGoodsAndServicesInfo";
import { formatDate } from "../../helpers/helpers";
import PageNotFound from "../errors/PageNotFound";


const TmrDetails = props => {
  const { translate } = useContext(I18nContext);
  const data = props.trademark.data;

  const handlePrint = () => {
    window.print();
  }

  const basicInfo = {
    applicationDate: formatDate(data.applicationDate),
    dossierStatus: data.dossierStatus,
    applicationNumber: data.applicationNumber,
    registrationDate: formatDate(data.registrationDate),
    expirationDate: formatDate(data.expirationDate),
    trademarkWord: data.trademarkWord,
    goodsAndServices: data.goodsAndServices,
    imageSpecification: data.imageSpecification
  };

  const detailedInfo = {
    filingNumber: data.filingNumber,
    trademarkType: data.trademarkType,
    trademarkDescriptions: data.trademarkDescriptions,
    imageSpecification: data.imageSpecification,
    processingLanguage: data.processingLanguage
  };

  useEffect(() => {
    props.fetchTrademark("trademark/tmr/" + props.match.params.applicationNumber);
  }, []);

  if (data.length < 1) {
    if(props.trademark.error)
      return (<PageNotFound title="trademark_not_found" description="trademark_not_found_description"/>);

    return null;
  }

  return (
    <Container className={"mt-4 mb-4"}>
      <Row className="align-items-center">
        <Col xs={8} className="pr-0">
          <h2>{translate("trademark.portlet.tmr.details")}</h2>
        </Col>
        <Col xs={4} className="d-flex justify-content-end d-print-none">
          <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={handlePrint}
            >
            <span>{translate("trademark.portlet.print")}</span>
          </button>
        </Col>
      </Row>
      <TmrBasicInfo data={basicInfo} />
      <TmrDetailedInfo data={detailedInfo} />
      <GoodsAndServicesInfo data={data.goodsAndServices} />
      <TargetGroupInfo data={data.targetGroup} />
      <PersonInfo
        title={translate("trademark.portlet.applicant.registered")}
        persons={data.applicants}
      />

      <PersonInfo
        title={translate("trademark.portlet.representative")}
        persons={data.representatives}
      />
      <CorrespondenceInfo data={data.correspondence} />
    </Container>
  );
};

export default withRouter(TmrDetails);
