import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import cardIcon from "../../../scss/styles/images/CardViewIcon.svg";
import gridIcon from "../../../scss/styles/images/GridViewIcon.svg";
import tableIcon from "../../../scss/styles/images/TableViewIcon.svg";
import UseWindowSize from "../../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../../constants/constants";

const ViewSelector = props => {
  const size = UseWindowSize();
  const { translate } = useContext(I18nContext);
  const handleClick = event => {
    props.changeView(event.target.id);
  };

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}
    >
      {size.width < bootStrapGrid.sm ? null : (
        <div>
          <span>{translate("trademark_searchresult_display_settings")}</span>
          <button
            id="table"
            className="btn-icon m-1 pb-0 pl-2 pr-2 pt-0"
            style={{ border: "none", backgroundColor: "white" }}
            onClick={handleClick}
            aria-label={translate("trademark_navigation_tableview")}
          >
            <abbr
              title={translate("trademark_navigation_tableview")}
              style={{ cursor: "pointer" }}
            >
              <img id="table" src={tableIcon} alt="" className="" height="22" />
            </abbr>
          </button>
        </div>
      )}
      <button
        id="prhCard"
        className="btn-icon m-1 pb-0 pl-2 pr-2 pt-0"
        style={{ border: "none", backgroundColor: "white" }}
        onClick={handleClick}
        aria-label={translate("trademark_navigation_prhcardview")}
      >
        <abbr
          title={translate("trademark_navigation_prhcardview")}
          style={{ cursor: "pointer" }}
        >
          <img id="prhCard" src={cardIcon} alt="" className="" height="22" />
        </abbr>
      </button>
      {props.path === "trademark" ? (
        <button
          id="masonry"
          className="btn-icon m-1 pb-0 pl-2 pr-2 pt-0"
          style={{ border: "none", backgroundColor: "white" }}
          onClick={handleClick}
          aria-label={translate("trademark_navigation_masonryview")}
        >
          <abbr
            title={translate("trademark_navigation_masonryview")}
            style={{ cursor: "pointer" }}
          >
            <img id="masonry" src={gridIcon} alt="" className="" height="22" />
          </abbr>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewSelector;
