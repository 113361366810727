import React from "react";
import { Row, Col } from "react-bootstrap";
import ResultCount from "./ResultCount";
import ViewSelector from "./ViewSelector";
import SortersAndFilters from "../sortersAndFilters/SortersAndFilters";
import UseWindowSize from "../../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../../constants/constants";

const ResultBar = (props) => {
  const size = UseWindowSize();
  return (
    <div>
      <Row className="mt-2 align-items-center pb-1">
        <Col
          xs={6}
          className="pr-0"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <ResultCount count={props.data.totalResults} data={props.data} />
        </Col>
        <Col xs={6} className="pl-0">
          <ViewSelector
            changeView={props.changeView}
            toggleShowSortersAndFilters={props.toggleShowSortersAndFilters}
            filtersSelected={props.filtersSelected}
            path={props.path}
          />
        </Col>
      </Row>
      {props.showSortersAndFilters && size.width < bootStrapGrid.lg ? (
        <SortersAndFilters
          setShowItems={props.setShowItems}
          data={props.data}
          path={props.path}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
          fetchSearchResults={props.fetchSearchResults}
          setFiltersSelected={props.setFiltersSelected}
        />
      ) : null}
    </div>
  );
};

export default ResultBar;
