import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import UseWindowSize from "../../../customHooks/useWindowSize";
import DataField from "../../common/DataField";
import { bootStrapGrid } from "../../../constants/constants";

const DetailedInfo = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  const fields = props.data;

  const showBoolean = (value) => {
    return value
      ? translate("trademark.boolean.true")
      : translate("trademark.boolean.false");
  };

  const trademarkType = (
    <DataField
      title={translate("trademark.portlet.mark.kind")}
      value={
        translate(
          "trademark.mark.kind." + fields.trademarkType.toLowerCase()
        ) || "-"
      }
    />
  );

  const description = (
    <DataField
      title={translate("trademark.portlet.mark.description")}
      value={
        fields.trademarkDescriptions.length < 1
          ? "-"
          : fields.trademarkDescriptions.map((item) => (
              <div key={item.ordinal}>
                <span lang={item.language.toLowerCase()}>
                  {item.description}
                </span>
                <br />
              </div>
            ))
      }
    />
  );
  const disclaimer = (
    <DataField
      title={translate("trademark.portlet.mark.disclaimer")}
      value={
        fields.trademarkDisclaimers.length < 1
          ? "-"
          : fields.trademarkDisclaimers.map((item) => (
              <div key={item.ordinal}>
                <span lang={item.language.toLowerCase()}>
                  {item.disclaimer}
                </span>
                <br />
              </div>
            ))
      }
    />
  );

  const getColourDescription = (colours) => {
    let ret = "";
    if (colours.length > 0) {
      ret = colours.map((item) => item).join(", ");
    }
    return ret;
  };

  const colourD = fields.imageSpecification.colourDescriptions;
  const colourDescriptions = (
    <div>
      {colourD &&
      (colourD.firstLanguage.length > 0 ||
        colourD.secondLanguage.length > 0) ? (
        <dl className="pb-2">
          <dt className="m-0 p-0">
            {translate("trademark.portlet.colour.descriptions")}
          </dt>
          <dd className="datafield-content m-0 p-0" lang="fi">
            {getColourDescription(colourD.firstLanguage)}
          </dd>
          <dd className=" datafield-content m-0 p-0" lang="sv">
            {getColourDescription(colourD.secondLanguage)}
          </dd>
        </dl>
      ) : (
        <dl className="pb-2">
          <dt className="datafield-content m-0 p-0">
            {translate("trademark.portlet.colour.descriptions")}
          </dt>
          <dd className="datafield-content">-</dd>
        </dl>
      )}
    </div>
  );

  const viennaClassification = (
    <DataField
      title={translate("trademark.portlet.vienna.classification")}
      value={
        !fields.imageSpecification.viennaClassifications
          ? "-"
          : fields.imageSpecification.viennaClassifications.length < 1
          ? "-"
          : fields.imageSpecification.viennaClassifications
              .map((item) => item)
              .join(" ")
      }
    />
  );
  const guaranteeOrCollective = (
    <DataField
      title={translate("trademark.portlet.guarantee.or.collective.tm")}
      value={showBoolean(fields.collectiveOrGuarantee) || "-"}
    />
  );
  const acceptanceReference = (
    <DataField
      title={translate("trademark_acceptance_reference")}
      value={fields.filingNumber || "-"}
    />
  );
  const processingLanguage = (
    <DataField
      title={translate("trademark_processing_language")}
      value={fields.processingLanguage || "-"}
    />
  );

  const mobileView = (
    <div>
      <Row>
        <Col xs={12}>{trademarkType}</Col>
      </Row>
      <Row>
        <Col xs={12}>{description}</Col>
      </Row>
      <Row>
        <Col xs={12}>{disclaimer}</Col>
      </Row>
      <Row>
        <Col xs={6}>{colourDescriptions}</Col>
        <Col xs={6}>{viennaClassification}</Col>
      </Row>
      <Row>
        <Col xs={12}>{guaranteeOrCollective}</Col>
      </Row>
      <Row>
        <Col xs={6}>{acceptanceReference}</Col>
        <Col xs={6}>{processingLanguage}</Col>
      </Row>
    </div>
  );
  const padView = (
    <div>
      <Row>
        <Col md={6}>{trademarkType}</Col>
        <Col md={3}>{colourDescriptions}</Col>
        <Col md={3}>{viennaClassification}</Col>
      </Row>
      <Row>
        <Col md={6}>{description}</Col>
        <Col md={6}>{guaranteeOrCollective}</Col>
      </Row>
      <Row>
        <Col md={6}>{disclaimer}</Col>
        <Col md={3}>{acceptanceReference}</Col>
        <Col md={3}>{processingLanguage}</Col>
      </Row>
    </div>
  );
  return (
    <div>
      <h3>{translate("trademark_detailed_info")}</h3>
      {size.width < bootStrapGrid.sm ? mobileView : padView}
    </div>
  );
};

export default DetailedInfo;
