import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import DataField from "../common/DataField";
import PersonInfo from "../common/PersonInfo";
import { formatDate } from "../../helpers/helpers";

const RPledgeInfo = props => {
  const { translate } = useContext(I18nContext);
  const fields = props.data.pledge;

  return (
    <div>
      <h3>{translate("pledge_details")}</h3>
      <Row>
        <Col xs={6} md={4}>
          <DataField
            title={translate("pledge_agreementdate")}
            value={formatDate(fields.agreementDate) || "-"}
          />
        </Col>
        <Col xs={6} md={4}>
          <DataField
            title={translate("pledge_granteddate")}
            value={formatDate(fields.grantedDate) || "-"}
          />
        </Col>
        <Col xs={6} md={4}>
          <DataField
            title={translate("pledge_expirationdate")}
            value={formatDate(fields.expirationDate) || "-"}
          />
        </Col>
      </Row>
      <PersonInfo
        title={translate("pledge_assignee")}
        persons={fields.assignees}
      />
    </div>
  );
};

export default RPledgeInfo;
