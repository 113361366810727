import React, { useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "../../common/Accordion";
import { I18nContext } from "../../../i18n";

const GoodsAndServicesInfo = props => {
  const { translate } = useContext(I18nContext);
  const [expandAll, setExpandAll] = useState(false);

  const accordionContent = items => (
    <ul className="mt-3">
      {items.map((item, index) => {
        return (
          <li key={item.ordinal}>
            {items.length - 1 === index ? item.text : item.text + ";"}
          </li>
        );
      })}
    </ul>
  );

  const handleClick = () => {
    setExpandAll(!expandAll);
  };

  return (
    <div className="mb-4">
      <Row>
        <Col xs={6}>
          <h3 className="mt-2">
            {translate("trademark.portlet.good.and.service.class")}
          </h3>
          <dt className="lead m-0">
            {props.data.version ? "NCL " + props.data.version : "NCL -"}
          </dt>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          {expandAll ? (
            <button
              type="button"
              className="btn btn-secondary btn-sm d-print-none"
              onClick={handleClick}
            >
              <span>{translate("trademark_close_all")}</span>
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-secondary btn-sm d-print-none"
              onClick={handleClick}
            >
              <span>{translate("trademark_open_all")}</span>
            </button>
          )}
        </Col>
      </Row>
      {props.data.classes.length < 1 ? (
        <span>-</span>
      ) : (
        props.data.classes.map(item => (
          <Accordion
            key={item.ordinal}
            id={item.ordinal}
            title={"Luokka " + item.classNumber}
            content={accordionContent(item.terms)}
            expand={expandAll}
            accepted={item.accepted}
          />
        ))
      )}
    </div>
  );
};

export default GoodsAndServicesInfo;
