import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import { formatDate } from "../../../helpers/helpers";

const PublicationsInfo = props => {
  const { translate } = useContext(I18nContext);

  return (
    <div className="pb-2">
      <h3>{translate("trademark.portlet.publications")}</h3>
      {props.data.length < 1 ? (
        <span>-</span>
      ) : (
        <div>
          {/*           <div class="table-responsive">
            <table
              id=""
              role="table"
              aria-colcount="2"
              aria-rowcount={props.data.length}
              class="table table-hover table-stacked-md"
            >
              <thead>
                <tr>
                  <th>{translate("trademark.portlet.publication.number")}</th>
                  <th>{translate("trademark.portlet.publication.kind")}</th>
                </tr>
              </thead>
              <tbody>
                {props.data.map(item => (
                  <tr>
                    {item.gazetteNumber ? (
                      <td>{item.gazetteNumber}</td>
                    ) : (
                      <td>-</td>
                    )}
                    {item.type ? (
                      <td>
                        {translate(
                          "trademark.publications." + item.type.toLowerCase()
                        )}
                      </td>
                    ) : (
                      <td>-</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
          <dl>
            <Row>
              <Col xs={4}>
                <dt>{translate("trademark.portlet.publication.number")}</dt>
              </Col>
              <Col xs={4}>
                <dt>{translate("trademark.portlet.publication.date")}</dt>
              </Col>
              <Col xs={4}>
                <dt>{translate("trademark.portlet.publication.kind")}</dt>
              </Col>
            </Row>
            {props.data.map(item => (
              <Row key={item.ordinal}>
                <Col xs={4}>
                  {item.gazetteNumber ? (
                    <dd className="datafield-content">{item.gazetteNumber}</dd>
                  ) : (
                    <dd className="datafield-content">-</dd>
                  )}
                </Col>
                <Col xs={4}>
                  {item.publicationDate ? (
                    <dd className="datafield-content">
                      {formatDate(item.publicationDate)}
                    </dd>
                  ) : (
                    <dd className="datafield-content">-</dd>
                  )}
                </Col>
                <Col xs={4}>
                  {item.type ? (
                    <dd className="datafield-content">
                      {translate(
                        "trademark.publications." + item.type.toLowerCase()
                      )}
                    </dd>
                  ) : (
                    <dd className="datafield-content">-</dd>
                  )}
                </Col>
              </Row>
            ))}
          </dl>
        </div>
      )}
    </div>
  );
};

export default PublicationsInfo;
