import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import Icons from "../../../scss/prh-icon-sprite.svg";
import ToolTip from "../../common/ToolTip";
import FilterField from "./FilterField";
import {
  allDossierStatuses,
  allTmrDossierStatuses,
} from "../../SearchForm/FormConstants";
import {
  validStatusFilters,
  nonvalidStatusFilters,
} from "../../../constants/constants";
import UseWindowSize from "../../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../../constants/constants";

const TmStatusFilter = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  const [showFilters, setShowFilters] = useState(false);
  const filters =
    props.path === "tmr" ? allTmrDossierStatuses : allDossierStatuses;

  const allSelected = () => {
    return filters.every((item) =>
      props.searchParams.dossierStatus.includes(item)
    );
  };

  const allRegisterdSelected = () => {
    return validStatusFilters.every((item) =>
      props.searchParams.dossierStatus.includes(item)
    );
  };

  const allNotRegisterdSelected = () => {
    return nonvalidStatusFilters.every((item) =>
      props.searchParams.dossierStatus.includes(item)
    );
  };

  const filterList = allSelected() ? [] : props.searchParams.dossierStatus;
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const selectSubSet = (valid) => {
    const all = allSelected();
    let newFilters = [];

    if (valid) {
      if (!allRegisterdSelected() || all) {
        newFilters = [...validStatusFilters];
      }

      if (!all) {
        nonvalidStatusFilters.forEach((item) => {
          if (props.searchParams.dossierStatus.includes(item)) {
            newFilters.push(item);
          }
        });
      }
    } else {
      if (!allNotRegisterdSelected() || all) {
        newFilters = [...nonvalidStatusFilters];
      }

      if (!all) {
        validStatusFilters.forEach((item) => {
          if (props.searchParams.dossierStatus.includes(item)) {
            newFilters.push(item);
          }
        });
      }
    }
    if (newFilters.length < 1) {
      newFilters = filters;
    }
    props.changeFilters(newFilters);
    props.setSearchParams({ ...props.searchParams, dossierStatus: newFilters });
  };

  const handleClick = (event) => {
    let newFilters = [...filterList];
    if (filterList.includes(event.currentTarget.value)) {
      newFilters = filterList.filter(
        (item) => item.toLowerCase() !== event.currentTarget.value.toLowerCase()
      );
    } else {
      newFilters.push(event.currentTarget.value);
    }
    if (newFilters.length < 1) {
      newFilters = filters;
    }
    props.changeFilters(newFilters);
    props.setSearchParams({ ...props.searchParams, dossierStatus: newFilters });
  };

  const clearFilters = () => {
    props.changeFilters(filters);
    props.setSearchParams({
      ...props.searchParams,
      dossierStatus: filters,
    });
    setShowFilters(true);
  };

  const arrows = (
    <button onClick={toggleFilters} className="select-btn p-0 m-0">
      {showFilters ? (
        <svg
          alt={translate("trademark_filtering_close")}
          className="filter-arrow-icon float-right p-0"
        >
          <use xlinkHref={`${Icons}#arrow-up`}></use>)
        </svg>
      ) : (
        <svg
          alt={translate("trademark_filtering_open")}
          className="filter-arrow-icon float-right p-0"
        >
          <use xlinkHref={`${Icons}#arrow-down`}></use>)
        </svg>
      )}
    </button>
  );

  const clearButton = (
    <button
      className="select-btn text-align-center p-0 pt-1 pb-1 m-0"
      style={{ textAlign: "center" }}
      onClick={clearFilters}
      data-cy="clearStatusFilters"
    >
      <span className="float-none">
        {translate("trademark.portlet.clear.button")}
      </span>
    </button>
  );

  const title = (
    <Row className="m-0 p-2 align-items-center">
      <Col xs={9} className="pr-0">
        <Row>
          <Col
            xs={"auto"}
            className="pr-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              onClick={toggleFilters}
              className="select-btn p-0 m-0"
              focus="false"
              style={{ width: "fit-content" }}
              data-cy="statusFilters"
            >
              <h4 className="selection-title m-0">
                {translate("trademark.portlet.filtering.current.status")}
              </h4>
            </button>
          </Col>
          <Col xs={"auto"} className="pl-0 pr-0">
            <ToolTip
              text={translate("trademark_infotext_statusfilter")}
              id="tmStatusTooltip"
              placement={size.width >= bootStrapGrid.lg ? "right" : "bottom"}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="pl-0">
        {allSelected() ? arrows : clearButton}
      </Col>
    </Row>
  );

  const subtitle = (value, valid) => {
    return (
      <Row
        className="m-0 pl-2 pt-3 pb-1"
        style={{ borderTop: "solid #ebebeb" }}
      >
        <Col>
          <h4
            className="selection-subtitle"
            onClick={() => selectSubSet(valid)}
          >
            {value}
          </h4>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <div
        aria-describedby="tmStatusTooltip"
        style={{ backgroundColor: "white" }}
      >
        <div>{title}</div>
        {showFilters || !allSelected() ? (
          props.path === "tmr" ? (
            filters.map((item) => (
              <FilterField
                text={translate("trademark.markstatus." + item.toLowerCase())}
                key={item}
                value={item}
                handleClick={handleClick}
                selected={filterList.includes(item)}
              />
            ))
          ) : (
            <div>
              {subtitle(
                translate("trademark_filtering_registered_or_pending"),
                true
              )}
              {validStatusFilters.map((item) => (
                <FilterField
                  text={translate("trademark.markstatus." + item.toLowerCase())}
                  key={item}
                  value={item}
                  handleClick={handleClick}
                  selected={filterList.includes(item)}
                />
              ))}
              {subtitle(translate("trademark_filtering_not_valid"), false)}
              {nonvalidStatusFilters.map((item) => (
                <FilterField
                  text={translate("trademark.markstatus." + item.toLowerCase())}
                  key={item}
                  value={item}
                  handleClick={handleClick}
                  selected={filterList.includes(item)}
                />
              ))}
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TmStatusFilter;
