import React, { useContext } from "react";
import { I18nContext } from "../../i18n";
import { formatDate, formatTrademarkPath } from "../../helpers/helpers";
import DataField from "../common/DataField";
import { Link, withRouter } from "react-router-dom";
import no_image from "../../scss/styles/images/no-image.svg";

const PrhCard = (props) => {
  const { translate } = useContext(I18nContext);
  const fields = props.data;

  const oldestPriority =
    fields.priorities.sort((a, b) =>
      a.priorityDate && b.priorityDate
        ? a.priorityDate.localeCompare(b.priorityDate)
        : 0
    )[0] || {};

  const trademarkCard = (
    <div className="prh-card">
      <Link
        /*         title={fields.trademarkWord} */
        aria-label={fields.trademarkWord + " " + fields.applicationNumber}
        className="p-0"
        to={{
          pathname: props.pathParams.buildPath( "trademark/" + formatTrademarkPath(fields) )
        }}
      >
        <div
          className="row smooth-5 m-1 mt-3 mb-3 p-2 p-sm-3 text-left"
          data-cy={"card-link-" + fields.applicationNumber}
        >
          <div className="col-5 col-md-3">
            <div className="row">
              <div className="col-12">
                {fields.trademarkHasImage ? (
                  <img
                    data-cy="image"
                    className="img-fluid"
                    src={fields.largeThumbnailUrl}
                    alt={translate("trademark_image")}
                  />
                ) : (
                  <div className="text-center">
                    <img
                      className="img-fluid prh-no-image"
                      src={no_image}
                      alt=""
                      data-cy="no_image"
                    />
                    <br />
                    <span data-cy="no_image_text">
                      {translate("trademark_no_image")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-7 col-md-3">
            <div className="row">
              <div className="col-12">
                <DataField
                  title={translate("trademark.portlet.mark.word")}
                  value={fields.trademarkWord || "-"}
                  data_cy="markword"
                />
              </div>
              <div className="col-12">
                <DataField
                  title={translate("trademark.portlet.classes")}
                  value={fields.goodsAndServices
                    .map((item) => item.classNumber)
                    .join(" ")}
                />
              </div>
              <div className="col-12 d-none d-md-block">
                {fields.representatives.length === 0 ? null : (
                  <DataField
                    title={translate("trademark.portlet.representative")}
                    value={fields.representatives
                      .map((item) => item.name)
                      .join(" ")}
                  />
                )}
              </div>
              <div className="col-12 d-none d-md-block">
                <DataField
                  title={translate("trademark.portlet.applicant")}
                  value={fields.applicants.map((item) => item.name).join(" ")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-6 col-md-12">
                <DataField
                  title={translate(
                    "trademark.portlet.result.header.application.number"
                  )}
                  value={
                    <span className="p-0">{fields.applicationNumber}</span>
                  }
                  data_cy="application_number"
                />
              </div>
              <div className="col-6 col-md-12">
                <DataField
                  title={translate(
                    "trademark.portlet.result.header.register.number"
                  )}
                  value={fields.registrationNumber || "-"}
                />
              </div>
              <div className="col-12 col-md-12">
                <DataField
                  title={translate("trademark.portlet.current.status")}
                  value={
                    translate(
                      "trademark.markstatus." +
                        fields.dossierStatus.toLowerCase()
                    ) || "-"
                  }
                />
              </div>
              <div className="col-12 d-none d-md-block">
                <DataField
                  title={translate("trademark.portlet.mark.kind")}
                  value={
                    translate(
                      "trademark.mark.kind." +
                        fields.dossierSubType.toLowerCase()
                    ) || "-"
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-6 col-md-12">
                <DataField
                  title={translate("trademark.portlet.priority.date")}
                  value={formatDate(oldestPriority.priorityDate) || "-"}
                />
              </div>
              <div className="col-6 col-md-12">
                <DataField
                  title={translate("trademark.portlet.application.date")}
                  value={formatDate(fields.applicationDate) || "-"}
                />
              </div>
              <div className="col-6 col-md-12 d-none d-md-block">
                <DataField
                  title={translate("trademark.portlet.registration.date")}
                  value={formatDate(fields.registrationDate) || "-"}
                  data_cy="registration_date"
                />
              </div>
              <div className="col-6 col-md-12 d-none d-md-block">
                <DataField
                  title={translate("trademark.portlet.expiry.date")}
                  value={formatDate(fields.expirationDate) || "-"}
                  data_cy="expiry_date"
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-md-none">
            <div className="row">
              <div className="col-12">
                <DataField
                  title={translate("trademark.portlet.applicant")}
                  value={fields.applicants.map((item) => item.name).join(" ")}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 d-md-none d-block">
                <DataField
                  title={translate("trademark.portlet.mark.kind")}
                  value={
                    translate(
                      "trademark.mark.kind." +
                        fields.dossierSubType.toLowerCase()
                    ) || "-"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );

  const tmrCard = (
    <div className="prh-card">
      <Link
        title={fields.trademarkWord}
        className="p-0"
        to={{
          pathname: props.pathParams.buildPath( "tmr/" + formatTrademarkPath(fields) )
        }}
      >
        <div
          className="row smooth-5 m-1 mt-3 mb-3 p-2 p-sm-3 text-left"
          data-cy="card-link"
        >
          <div className="col-5 col-md-3">
            <div className="row">
              <div className="col-12">
                {fields.trademarkHasImage ? (
                  <img
                    data-cy="image"
                    className="img-fluid"
                    src={fields.largeThumbnailUrl}
                    alt={translate("trademark_image")}
                  />
                ) : (
                  <div className="text-center">
                    <img
                      className="img-fluid prh-no-image"
                      src={no_image}
                      alt=""
                      data-cy="no_image"
                    />
                    <br />
                    <span data-cy="no_image_text">
                      {translate("trademark_no_image")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-7 col-md-9">
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="row">
                  <div className="col-12">
                    <DataField
                      title={translate("trademark.portlet.mark.word")}
                      value={fields.trademarkWord || "-"}
                      data_cy="markword"
                    />
                  </div>
                  <div className="col-12">
                    <DataField
                      title={translate("trademark.portlet.target.group")}
                      value={fields.targetGroup || "-"}
                      data_cy="targetGroup"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-none d-md-block">
                <div className="row">
                  <div className="col-md-12">
                    <DataField
                      title={translate("trademark.portlet.number")}
                      value={fields.applicationNumber}
                      data_cy="application_number"
                    />
                  </div>
                  <div className="col-md-12">
                    <DataField
                      title={translate("trademark.portlet.classes")}
                      value={fields.goodsAndServices
                        .map((item) => item.classNumber)
                        .join(" ")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-none d-md-block">
                <div className="row">
                  <div className="col-md-12">
                    <DataField
                      title={translate("trademark.portlet.application.date")}
                      value={formatDate(fields.applicationDate) || "-"}
                    />
                  </div>
                  <div className="col-md-12">
                    <DataField
                      title={translate("trademark.portlet.approval.date")}
                      value={formatDate(fields.registrationDate) || "-"}
                      data_cy="registration_date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 d-none d-md-block">
                <DataField
                  title={translate("trademark.portlet.applicant")}
                  value={fields.applicants.map((item) => item.name).join(" ")}
                />
              </div>
              <div className="col-md-8 d-none d-md-block">
                <DataField
                  title={translate("trademark.portlet.current.status")}
                  value={translate(
                    "trademark.markstatus." + fields.dossierStatus.toLowerCase()
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-md-none d-block">
            <div className="row">
              <div className="col-6 col-md-12">
                <DataField
                  title={translate("trademark.portlet.number")}
                  value={
                    <span className="p-0">{fields.applicationNumber}</span>
                  }
                  data_cy="application_number"
                />
              </div>
              <div className="col-6">
                <DataField
                  title={translate("trademark.portlet.classes")}
                  value={fields.goodsAndServices
                    .map((item) => item.classNumber)
                    .join(" ")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-md-none d-block">
            <div className="row">
              <div className="col-12">
                <DataField
                  title={translate("trademark.portlet.current.status")}
                  value={translate(
                    "trademark.markstatus." + fields.dossierStatus.toLowerCase()
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-md-none d-block">
            <div className="row">
              <div className="col-6">
                <DataField
                  title={translate("trademark.portlet.application.date")}
                  value={formatDate(fields.applicationDate) || "-"}
                />
              </div>
              <div className="col-6">
                <DataField
                  title={translate("trademark.portlet.approval.date")}
                  value={formatDate(fields.registrationDate) || "-"}
                  data_cy="registration_date"
                />
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-md-none">
            <div className="row">
              <div className="col-12">
                <DataField
                  title={translate("trademark.portlet.applicant")}
                  value={fields.applicants.map((item) => item.name).join(" ")}
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );

  return <div> {props.path === "tmr" ? tmrCard : trademarkCard}</div>;
};
export default withRouter(PrhCard);
