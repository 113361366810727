import React, { useContext } from "react";
import { I18nContext } from "../../../i18n";
import downloadIcon from "../../../scss/styles/images/DownloadIcon.svg";
import { exportCSVFile } from "../../../helpers/csvExport";

const ResultCount = props => {
  const { translate } = useContext(I18nContext);
  const handleClick = () => {
    exportCSVFile(props.data.results, "results");
  };
  return (
    <div>
      <span className="pr-1">
        {translate("search_result_count") + " " + props.count}
        {props.count > 3000
          ? " / " + translate("trademark_search_show_amount")
          : ""}
      </span>
      <button
        onClick={handleClick}
        className="btn-icon pb-0 pl-1 pt-0"
        style={{ border: "none", backgroundColor: "white" }}
      >
        <abbr
          title={translate("trademark_download_csv")}
          style={{ cursor: "pointer" }}
        >
          <img
            src={downloadIcon}
            alt={translate("trademark_download_csv")}
            id="download"
            className="pb-1 pl-1"
            height="22"
          />
        </abbr>
      </button>
    </div>
  );
};

export default ResultCount;
