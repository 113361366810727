import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import DataField from "../common/DataField";
import UseWindowSize from "../../customHooks/useWindowSize";
import { bootStrapGrid } from "../../constants/constants";

const KBasicInfo = props => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  const fields = props.data;
  const handlePrint = () => {
    window.print();
  }
  const applicationNumber = (
    <DataField
      title={translate("trademark.portlet.application.number")}
      value={fields.applicationNumber || "-"}
    />
  );
  const applicationDate = (
    <DataField
      title={translate("trademark.portlet.application.date")}
      value={fields.applicationDate || "-"}
    />
  );
  const sentToWIPO = (
    <DataField
      title={translate("k_application_sent_to_WIPO")}
      value={fields.sentToWIPO || "-"}
    />
  );
  const registrationNumber = (
    <DataField
      title={translate("trademark.portlet.register.number")}
      value={fields.registrationNumber || "-"}
    />
  );
  const registrationDate = (
    <DataField
      title={translate("trademark.portlet.register.date")}
      value={fields.registrationDate || "-"}
    />
  );
  const expirationDate = (
    <DataField
      title={translate("trademark.portlet.expiry.date")}
      value={fields.expirationDate || "-"}
    />
  );
  const currentStatus = (
    <DataField
      title={translate("trademark.portlet.current.status")}
      value={
        translate(
          "trademark.event.status.trademarkinternationalapp." +
            fields.dossierStatus.toLowerCase()
        ) || "-"
      }
    />
  );
  const mobileView = (
    <Row>
      <Col xs={6}>
        {applicationNumber}
        {applicationDate}
        {sentToWIPO}
        {currentStatus}
      </Col>
      <Col xs={6}>
        {registrationNumber}
        {registrationDate}
        {expirationDate}
      </Col>
    </Row>
  );

  const padView = (
    <Row>
      <Col md={4}>
        {applicationNumber}
        {applicationDate}
        {sentToWIPO}
      </Col>
      <Col md={4}>
        {registrationNumber}
        {registrationDate}
      </Col>
      <Col md={4}>
        {expirationDate}
        {currentStatus}
      </Col>
    </Row>
  );
  return (
    <div>
      <Row>
        <Col xs={8}>
          <h2>{translate("r_application_basic_info")}</h2>
        </Col>
        <Col xs={4} className="d-flex justify-content-end d-print-none">
          <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={handlePrint}
            >
            <span>{translate("trademark.portlet.print")}</span>
          </button>
        </Col>
      </Row>
      {size.width < bootStrapGrid.sm ? mobileView : padView}
    </div>
  );
};

export default KBasicInfo;
