import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import { formatTrademarkPath } from "../../helpers/helpers";
import { Link, withRouter } from "react-router-dom";

const RDetailedInfo = (props) => {
  const { translate } = useContext(I18nContext);
  const fields = props.data;

  const targetedTrademarks = fields.relatedTrademarks.map((item) => {
    return (
      <Col key={item.ordinal} xs={4} sm={3} md={2} data-cy="related-tm">
        <Link
          className="p-0"
          to={{
            pathname: props.pathParams.buildPath( "trademark/" + formatTrademarkPath(item) )
          }}
        >
          {item.registrationNumber
            ? item.registrationNumber
            : item.applicationNumber}
        </Link>
      </Col>
    );
  });

  return (
    <div>
      <h2>{translate("trademark_detailed_info")}</h2>
      <h3>{translate("targeted_trademarks")}</h3>
      <Row className="mb-4">{targetedTrademarks}</Row>
    </div>
  );
};

export default withRouter(RDetailedInfo);
