import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import DataField from "../common/DataField";

const PersonInfo = props => {
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <h3>{props.title}</h3>
      {props.persons.length < 1 ? (
        <span>-</span>
      ) : (
        props.persons.map(person => (
          <Row key={person.ordinal}>
            <Col xs={6} md={4}>
              <DataField
                title={translate("trademark.portlet.applicant.name")}
                value={
                  person.personType === "LEGAL_ENTITY"
                    ? person.organizationName
                    : person.firstName + " " + person.lastName
                }
              />
            </Col>
            <Col xs={6} md={4}>
              <DataField
                title={translate("trademark.portlet.applicant.domicile")}
                value={person.domicile.firstLanguage}
              />
            </Col>
            <Col xs={12} md={4}>
              <DataField
                title={translate("trademark.portlet.applicant.country")}
                value={person.domicile.country}
              />
            </Col>
          </Row>
        ))
      )}
    </div>
  );
};

export default PersonInfo;
