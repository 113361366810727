const convertToCSV = (results) => {
    // Add double quotes and handle null values
    const sanitize = (item) => item ? '"' + item.replace(/"/g, '""') + '"' : '';
    // Pick headers from the first search result 
    let header = Object.keys(results[0]);
    // Remove dossierId
    header.shift();
    // Remove oridinal header
    header.pop();
    
    let csv = results.map((row) => { 
        row = flatten(row);
        
        let result = header.map((fieldName) => {
            const keys = Object.keys(row);
            let values = [];
            keys.forEach(key=> {
                // Handle all child items except ordinals
                if(key.includes(fieldName) && !key.includes('ordinal')) {
                    values.push(row[key]);
                }
            });
            return sanitize(values.join(','));
        }).join(';');
        return result;
    });
    // Add headers
    csv.unshift(header.join(';'))
    csv = csv.join('\r\n')
    return csv;
}

const flatten = (obj, prefix, current) => {
    prefix = prefix || []
    current = current || {}
  
    if (typeof (obj) === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        flatten(obj[key], prefix.concat(key), current)
      })
    } else {
      current[prefix.join('.')] = obj
    }
  
    return current
  }

export const exportCSVFile = (results, fileTitle) => {
    var csv = convertToCSV(results);

    var exportedFilenmae = fileTitle + '.csv' || 'results.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export default false;