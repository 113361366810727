import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import SearchResults from "../components/SearchResults/SearchResults";
import BasicSearch from "../components/SearchForm/BasicSearch";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import { I18nContext } from "../i18n";
import WarningMessage from "../components/errors/WarningMessage";
import Footer from "../components/common/Footer";
import SearchBar from "../components/SearchForm/SearchBar";
import {
  allDossierStatuses,
  allTmrDossierStatuses,
} from "../components/SearchForm/FormConstants";
import { typeFilters } from "../constants/constants";

const SearchResultPage = (props) => {
  const [errors, setErrors] = useState([]);
  const [showSortersAndFilters, setShowSortersAndFilters] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState(false);
  const size = UseWindowSize();
  const { translate } = useContext(I18nContext);
  document.title = translate("application_title_searchresults") + " | PRH";
  let editable = false;

  useEffect(() => {
    setErrors(props.searchResult.error);
  }, [props.searchResult.error]);

  useEffect(() => {
    filterSelectionActive();
  }, [props.searchParams]);

  useEffect(() => {
    props.pathParams.updateLanguage();
  }, []);

  const clearError = (index) => {
    if (errors.length > 1) {
      let newErrors = errors.filter((val, i) => i !== index);
      setErrors(newErrors);
    } else {
      setErrors([]);
    }
  };

  const filterSelectionActive = () => {
    const statusFilters =
      props.path === "tmr" ? allTmrDossierStatuses : allDossierStatuses;
    let allSelected = true;
    typeFilters.forEach((item) => {
      if (!props.searchParams.trademarkKind.includes(item)) {
        allSelected = false;
      }
    });
    statusFilters.forEach((item) => {
      if (!props.searchParams.dossierStatus.includes(item)) {
        allSelected = false;
      }
    });
    setFiltersSelected(!allSelected);
  };

  const toggleShowSortersAndFilters = () => {
    setShowSortersAndFilters(!showSortersAndFilters);
  };

  if (props.location && props.location.state && props.location.state.editable) {
    editable = props.location.state.editable;
  }

  return (
    <>
      <Container fluid="true" className="flex-wrapper m-0 p-0">
        <div>
          <div id="skip">
            <a href="#result_content">{translate("jumplink_to_content")}</a>
          </div>
          {size.width < bootStrapGrid.md ? (
            <MobileNavbar pathParams={props.pathParams} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              pathParams={props.pathParams}
            />
          )}
          {errors &&
            errors.map((error, index) => {
              return (
                <WarningMessage
                  errorTitle={error.errorTitle}
                  errorContent={error.errorContent}
                  clearError={() => clearError(index)}
                />
              );
            })}
          <main id="result_content" tabIndex="-1">
            {!props.searchResult.loading &&
              !errors &&
              props.searchResult.length > 0 && (
                <Container>
                  {props.path === "trademark" && (
                    <h3 className="mt-2">{translate("search_trademark")}</h3>
                  )}
                  {props.path === "tmr" && (
                    <h3 className="mt-2">{translate("search_tmr")}</h3>
                  )}
                </Container>
              )}
            {editable ? (
              <SearchBar
                toggleShowSortersAndFilters={toggleShowSortersAndFilters}
                filtersSelected={filtersSelected}
              />
            ) : (
              <BasicSearch
                toggleShowSortersAndFilters={toggleShowSortersAndFilters}
                filtersSelected={filtersSelected}
                fetchSearchResults={props.fetchSearchResults}
                expand={props.searchResult.data.length === 0}
                setSearchParams={props.setSearchParams}
                searchParams={props.searchParams}
                path={props.path}
                editable={editable}
                pathParams={props.pathParams}
              />
            )}
            <SearchResults
              toggleShowSortersAndFilters={toggleShowSortersAndFilters}
              showSortersAndFilters={showSortersAndFilters}
              setFiltersSelected={setFiltersSelected}
              fetchSearchResults={props.fetchSearchResults}
              searchParams={props.searchParams}
              setSearchParams={props.setSearchParams}
              viewMode={props.viewMode}
              setViewMode={props.setViewMode}
              data={props.searchResult.data}
              loading={props.searchResult.loading}
              path={props.path}
              onChangePage={props.onChangePage}
              currentPage={props.currentPage}
              masonryPage={props.masonryPage}
              pathParams={props.pathParams}
            />
          </main>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withRouter(SearchResultPage);
