import React, { useState, useContext } from "react";
import { I18nContext } from "../../i18n";
import { Col, Row } from "react-bootstrap";
import NavMenu from "./NavMenu";
import PrhBrand from "./PrhBrand";

const MobileNavbar = ( props ) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { translate } = useContext(I18nContext);

  return (
    <header className="prh-site-header">
      <div className="container prh-site-header__container">
        <nav className="navbar navbar-dark navbar-expand-md">
          <Row className="ml-0" style={{ width: "100%" }}>
            <Col xs={10} className="pt-1 pl-0 pr-0">
              <PrhBrand pathParams = { props.pathParams }/>
            </Col>
            <Col xs={2} className="pt-3 pr-0">
              <button
                aria-label={
                  menuVisible
                    ? translate("trademark_navigation_hide")
                    : translate("trademark_navigation_show")
                }
                aria-controls="nav_collapse"
                aria-expanded={menuVisible}
                data-cy="menu"
                type="button"
                onClick={() => setMenuVisible(!menuVisible)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </Col>
          </Row>
        </nav>
        {menuVisible ? <NavMenu id="nav_collapse" pathParams = { props.pathParams }/> : null}
      </div>
    </header>
  );
};

export default MobileNavbar;
