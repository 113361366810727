import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import DataField from "../common/DataField";
import PersonInfo from "../common/PersonInfo";
import { formatDate } from "../../helpers/helpers";

const RLicenseInfo = props => {
  const { translate } = useContext(I18nContext);
  const fields = props.data.license;

  const showBoolean = value => {
    return value
      ? translate("trademark.boolean.true")
      : translate("trademark.boolean.false");
  };

  return (
    <div>
      <h3>{translate("license_details")}</h3>
      <Row>
        <Col xs={6} md={4}>
          <DataField
            title={translate("license_agreement")}
            value={formatDate(fields.agreementDate) || "-"}
          />
          <DataField
            title={translate("exclusive")}
            value={showBoolean(fields.exclusive)}
          />
        </Col>
        <Col xs={6} md={4}>
          <DataField
            title={translate("start_date")}
            value={formatDate(fields.grantedDate) || "-"}
          />
          <DataField
            title={translate("end_date")}
            value={formatDate(fields.expirationDate) || "-"}
          />
        </Col>
        <Col xs={12} md={4}>
          <Row>
            <Col xs={6} md={12}>
              <DataField
                title={translate("sub_license")}
                value={showBoolean(fields.sublicence)}
              />
            </Col>
            <Col xs={6} md={12}>
              <DataField
                title={translate("partial_license")}
                value={showBoolean(fields.partial)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <PersonInfo
        title={translate("use_permission_possessor")}
        persons={fields.assignees}
      />
    </div>
  );
};

export default RLicenseInfo;
