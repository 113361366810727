import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import BasicInfo from "./infoComponents/TmBasicInfo";
import DetailedInfo from "./infoComponents/TmDetailedInfo";
import PersonInfo from "../common/PersonInfo";
import PublicationsInfo from "./infoComponents/TmPublicationsInfo";
import CorrespondenceInfo from "../common/CorrespondenceInfo";
import GoodsAndServicesInfo from "./infoComponents/TmGoodsAndServicesInfo";
import RelatedDossierInfo from "./infoComponents/TmRelatedDossierInfo";
import { formatDate } from "../../helpers/helpers";
import Icons from "../../scss/prh-icon-sprite.svg";
import PageNotFound from "../errors/PageNotFound";


const TrademarkDetails = (props) => {
  const { translate } = useContext(I18nContext);
  const [showVideo, setShowVideo] = useState(false);
  const [showAudio, setShowAudio] = useState(false);
  const data = props.trademark.data;

  const findOldestDate = (list) => {
    if (!list || list.length < 1) {
      return "";
    }
    let dates = [];
    list.forEach((item) =>
      item.priorityDate ? dates.push(item.priorityDate) : null
    );
    return dates.sort()[0];
  };

  const toggleShowVideo = () => {
    setShowVideo(!showVideo);
  };

  const toggleShowAudio = () => {
    setShowAudio(!showAudio);
  };

  const handlePrint = () => {
    window.print();
  };

  const basicInfo = {
    applicationDate: formatDate(data.applicationDate),
    dossierStatus: data.dossierStatus,
    applicationNumber: data.applicationNumber,
    oppositionPeriodStartDate: formatDate(data.oppositionPeriodStartDate),
    oppositionPeriodEndDate: formatDate(data.oppositionPeriodEndDate),
    registrationDate: formatDate(data.registrationDate),
    usageObligationStartDate: formatDate(data.usageObligationStartDate),
    expirationDate: formatDate(data.expirationDate),
    priorityDate: formatDate(findOldestDate(data.priorities)),
    trademarkWord: data.trademarkWord,
    registrationNumber: data.registrationNumber,
    goodsAndServices: data.goodsAndServices,
    imageSpecification: data.imageSpecification,
    priorities: data.priorities,
  };

  const detailedInfo = {
    filingNumber: data.filingNumber,
    trademarkType: data.trademarkType,
    trademarkDescriptions: data.trademarkDescriptions,
    trademarkDisclaimers: data.trademarkDisclaimers,
    imageSpecification: data.imageSpecification,
    processingLanguage: data.processingLanguage,
    collectiveOrGuarantee: data.collectiveTrademark || data.guaranteeTrademark,
  };

  const fetchTrademark = props.fetchTrademark;
  let path = "";
  props.match.params.registrationNumber
    ? (path =
        "trademark/" +
        props.match.params.applicationNumber +
        "/" +
        props.match.params.registrationNumber)
    : (path = "trademark/" + props.match.params.applicationNumber);

  useEffect(() => {
    fetchTrademark(path);
  }, []);

  if (data.length < 1) {
    if(props.trademark.error)
      return (<PageNotFound title="trademark_not_found" description="trademark_not_found_description"/>);

    return null;
  }

  const videoElement = (
    <video
      controls
      src={data.videoSpecification ? data.videoSpecification.videoUrl : ""}
      style={{maxWidth:"100%"}}
    ></video>
  );
  const videoButton = (
    <button
      data-cy="videoButton"
      onClick={toggleShowVideo}
      className="btn-icon float-right m-1 pl-2 pr-2"
      style={{ border: "none", backgroundColor: "white" }}
    >
      <svg
        alt={translate("trademark_show_video")}
        className="prh-icon prh-video-audio-icon"
      >
        <use xlinkHref={`${Icons}#video`}></use>)
      </svg>
    </button>
  );

  const audioElement = (
    <audio
      controls
      src={data.soundSpecification ? data.soundSpecification.soundUrl : ""}
    ></audio>
  );
  const audioButton = (
    <button
      data-cy="audioButton"
      onClick={toggleShowAudio}
      className="btn-icon float-right m-1 pl-2 pr-2"
      style={{
        border: "none",
        backgroundColor: "white",
      }}
    >
      <svg
        alt={translate("trademark_play_sound")}
        className="prh-icon prh-video-audio-icon"
      >
        <use xlinkHref={`${Icons}#volume-1`}></use>)
      </svg>
    </button>
  );
  return (
    <Container className={"mt-2 mb-4"}>
      <Row className="align-items-center">
        <Col xs={6} className="pr-0">
          <h2 className="m-0">{translate("trademark.portlet.tm.details")}</h2>
        </Col>
        <Col xs={3} className="pl-0">
          {data.videoSpecification.videoUrl && videoButton}
          {data.soundSpecification.soundUrl && audioButton}
        </Col>
        <Col xs={3} className="d-flex justify-content-end d-print-none">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={handlePrint}
          >
            <span>{translate("trademark.portlet.print")}</span>
          </button>
        </Col>
      </Row>
      {showVideo && videoElement}
      {showAudio && audioElement}
      <BasicInfo data={basicInfo} />
      <DetailedInfo data={detailedInfo} />
      <GoodsAndServicesInfo data={data.goodsAndServices} />

      <PersonInfo
        title={translate("trademark.portlet.applicant.registered")}
        persons={data.applicants}
      />

      <PersonInfo
        title={translate("trademark.portlet.representative")}
        persons={data.representatives}
      />
      <CorrespondenceInfo data={data.correspondence} />
      <RelatedDossierInfo data={data.relatedDossiers} pathParams = { props.pathParams }/>
      <PublicationsInfo data={data.publications} />
    </Container>
  );
};

export default withRouter(TrademarkDetails);
