import React, { useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import Icons from "../../../scss/prh-icon-sprite.svg";
import FilterField from "./FilterField";
import ToolTip from "../../common/ToolTip";
import { typeFilters } from "../../../constants/constants";

const TmTypeFilter = (props) => {
  const { translate } = useContext(I18nContext);
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const allSelected = () => {
    let selected = true;
    typeFilters.forEach((item) => {
      if (!props.searchParams.trademarkKind.includes(item)) {
        selected = false;
      }
    });
    return selected;
  };

  const filterList = allSelected() ? [] : props.searchParams.trademarkKind;

  const handleClick = (event) => {
    let newFilters = [...filterList];
    if (filterList.includes(event.currentTarget.value)) {
      newFilters = filterList.filter(
        (item) => item.toLowerCase() !== event.currentTarget.value.toLowerCase()
      );
    } else {
      newFilters.push(event.currentTarget.value);
    }
    if (newFilters.length < 1) {
      newFilters = typeFilters;
    }
    props.changeFilters(newFilters);
    props.setSearchParams({ ...props.searchParams, trademarkKind: newFilters });
  };

  const clearFilters = () => {
    const newParams = { ...props.searchParams, trademarkKind: typeFilters };
    props.changeFilters(typeFilters);
    props.setSearchParams(newParams);
    setShowFilters(true);
  };

  const arrows = (
    <button onClick={toggleFilters} className="select-btn p-0 m-0">
      {showFilters ? (
        <svg
          alt={translate("trademark_filtering_close")}
          className="filter-arrow-icon float-right p-0"
        >
          <use xlinkHref={`${Icons}#arrow-up`}></use>)
        </svg>
      ) : (
        <svg
          alt={translate("trademark_filtering_open")}
          className="filter-arrow-icon float-right p-0"
        >
          <use xlinkHref={`${Icons}#arrow-down`}></use>)
        </svg>
      )}
    </button>
  );

  const clearButton = (
    <button
      className="select-btn text-align-center p-0 pt-1 pb-1 m-0"
      style={{ textAlign: "center" }}
      onClick={clearFilters}
      data-cy="clearTypeFilters"
    >
      <span className="float-none">
        {translate("trademark.portlet.clear.button")}
      </span>
    </button>
  );

  const titleField = (
    <Row className="m-0 p-2 align-items-center">
      <Col xs={9} className="pr-0">
        <Row>
          <Col
            xs={"auto"}
            className="pr-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              onClick={toggleFilters}
              className="select-btn p-0 m-0"
              focus="false"
              data-cy="typeFilters"
            >
              <h4 className="selection-title m-0">
                {translate("trademark.portlet.filtering.mark.kind")}
              </h4>
            </button>
          </Col>
          <Col xs={"auto"} className="pl-0 pr-0">
            <ToolTip
              text={translate("trademark_infotext_typefilter")}
              id="tmTypeTooltip"
            />
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="pl-0">
        {allSelected() ? arrows : clearButton}
      </Col>
    </Row>
  );

  return (
    <div>
      <div
        aria-describedby="tmTypeTooltip"
        style={{ backgroundColor: "white" }}
      >
        {titleField}
        {showFilters || !allSelected() ? (
          <div>
            {typeFilters.map((item) => (
              <FilterField
                text={translate("trademark.mark.kind." + item.toLowerCase())}
                key={item}
                value={item}
                handleClick={handleClick}
                selected={filterList.includes(item)}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TmTypeFilter;
