import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import UseWindowSize from "../../../customHooks/useWindowSize";
import ResultSorter from "./ResultSorter";
import TmStatusFilter from "./TmStatusFilter";
import TmTypeFilter from "./TmTypeFilter";
import { bootStrapGrid } from "../../../constants/constants";

const SortersAndFilters = (props) => {
  const size = UseWindowSize();

  const onStatusFilterChange = (dossierStatus) => {
    const newParams = { ...props.searchParams, dossierStatus };
    props.fetchSearchResults(newParams, props.path);
  };

  const onTypeFilterChange = (trademarkKind) => {
    const newParams = { ...props.searchParams, trademarkKind };
    props.fetchSearchResults(newParams, props.path);
  };

  const singleColumnView = (
    <Row>
      <Col className="pb-1" xs={12}>
        <ResultSorter
          setShowItems={props.setShowItems}
          data={props.data}
          path={props.path}
        />
      </Col>
      <Col className="pb-1" xs={12}>
        <TmStatusFilter
          changeFilters={onStatusFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
          path={props.path}
        />
      </Col>
      <Col xs={12}>
        <TmTypeFilter
          changeFilters={onTypeFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />
      </Col>
    </Row>
  );
  const doubleColumnView = (
    <Row>
      <Col className="pr-1" md={6}>
        <div className="pb-1">
          <ResultSorter
            setShowItems={props.setShowItems}
            data={props.data}
            path={props.path}
          />
        </div>
        <TmTypeFilter
          changeFilters={onTypeFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
        />
      </Col>
      <Col className="pl-1" xs={12} md={6}>
        <TmStatusFilter
          changeFilters={onStatusFilterChange}
          searchParams={props.searchParams}
          setSearchParams={props.setSearchParams}
          path={props.path}
        />
      </Col>
    </Row>
  );
  return (
    <Container className="p-1" style={{ backgroundColor: "#d9d9d9" }}>
      {size.width < bootStrapGrid.sm || size.width >= bootStrapGrid.lg
        ? singleColumnView
        : doubleColumnView}
    </Container>
  );
};

export default SortersAndFilters;
