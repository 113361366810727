import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import DataField from "../common/DataField";

const RBasicInfo = props => {
  const { translate } = useContext(I18nContext);
  const fields = props.data;
  const handlePrint = () => {
    window.print();
  }

  return (
    <div>
      <Row>
        <Col xs={8}>
          <h2>{translate("r_application_basic_info")}</h2>
        </Col>
        <Col xs={4} className="d-flex justify-content-end d-print-none">
          <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={handlePrint}
            >
            <span>{translate("trademark.portlet.print")}</span>
          </button>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={4}>
          <DataField
            title={translate("trademark.portlet.application.number")}
            value={fields.applicationNumber || "-"}
          />
          <DataField
            title={translate("application_type")}
            value={
              translate(
                "trademark.event.type.trademarkrecordal." + fields.recordalType
              ) || "-"
            }
          />
        </Col>
        <Col xs={6} md={4}>
          <DataField
            title={translate("trademark.portlet.application.date")}
            value={fields.applicationDate || "-"}
          />
          <DataField
            title={translate("tagged_in_register")}
            value={
              fields.dossierStatus === "Application_accepted"
                ? fields.dossierStatusDate || "-"
                : "-"
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <DataField
            title={translate("trademark.portlet.current.status")}
            value={
              translate(
                "trademark.event.status.trademarkrecordal." +
                  fields.dossierStatus.toLowerCase()
              ) || "-"
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default RBasicInfo;
