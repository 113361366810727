import React, { useContext, useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { I18nContext } from "../../i18n";
import { withRouter } from "react-router-dom";
import { allDossierStatuses, registeredDossierStatuses } from "./FormConstants";
import { typeFilters } from "../../constants/constants";
import { initialSearchParams } from "../../constants/constants";
import { validateSearchForm } from "./formValidators";
import ToolTip from "../common/ToolTip";
import DatePick from "../common/DatePicker";
import { format } from "date-fns";

const SearchForm = (props) => {
  const { translate } = useContext(I18nContext);
  const [validationErrors, setValidationErrors] = useState([]);
  const fields = props.searchParams;

  let radioValue = "";
  if (
    fields.dossierStatus.length < 1 ||
    fields.dossierStatus === allDossierStatuses ||
    fields.dossierStatus === "all"
  ) {
    radioValue = "all";
  } else {
    radioValue = "registered";
  }

  const ref = React.createRef();
  const handleReset = () => {
    props.setSearchParams(initialSearchParams);
    setValidationErrors([]);
  };

  const handleChange = (event) => {
    const newSearchParams = {
      ...props.searchParams,
      [event.target.name]: event.target.value,
    };
    props.setSearchParams(newSearchParams);
  };

  const handleDateChange = (id, date) => {
    let isoDate = date ? format(date, "yyyy-MM-dd") : "";
    const newSearchParams = {
      ...props.searchParams,
      [id]: isoDate,
    };
    props.setSearchParams(newSearchParams);
  };

  const handleFocusOut = () => {
    setValidationErrors(validateSearchForm(fields));
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      setValidationErrors(validateSearchForm(fields));
    }
  };

  const validTrademarkWord = !validationErrors.includes("trademarkWord");
  const validApplicationNumber = !validationErrors.includes(
    "applicationNumber"
  );
  const validRegistrationNumber = !validationErrors.includes(
    "registrationNumber"
  );
  const validFilingNumber = !validationErrors.includes("filingNumber");
  const validApplicantName = !validationErrors.includes("applicantName");
  const validRepresentativeName = !validationErrors.includes(
    "representativeName"
  );
  const validBusinessID = !validationErrors.includes("businessID");
  const validGoodsAndServicesClassNumber = !validationErrors.includes(
    "goodsAndServicesClassNumber"
  );
  const validGoodsAndServicesTerm = !validationErrors.includes(
    "goodsAndServicesTerm"
  );
  const validViennaClass = !validationErrors.includes("viennaClass");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validationErrors.length > 0) {
      document.getElementById(validationErrors[0]).focus();
    } else {
      const dossierStatuses =
        fields.dossierStatus === "registered"
          ? registeredDossierStatuses
          : allDossierStatuses;

      const searchParams = fields;
      searchParams.basicSearch = "";
      searchParams.dossierStatus = dossierStatuses;
      searchParams.trademarkKind = typeFilters;
      props.setSearchParams(searchParams);

      props.fetchSearchResults(searchParams, "trademark");
      props.history.push({
        pathname: props.pathParams.buildPath( "results" ),
        state: { editable: true },
      });
    }
  };

  return (
    <div className="prh-form">
      <div className="prh-form__container">
        <section className="prh-section-borders">
          <h2>{translate("trademark_advanced_search_title")}</h2>
          <Form onSubmit={handleSubmit} ref={ref}>
            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label className="mb-0" htmlFor="trademarkWord">
                  {translate("trademark.portlet.mark.word")}
                </Form.Label>
                <ToolTip
                  text={translate(
                    "trademark_infotext_searchForm_trademarkWord"
                  )}
                  id="trademarkWordTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validTrademarkWord ? "border border-dark" : "is-invalid"
                }
                id="trademarkWord"
                name="trademarkWord"
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                value={fields.trademarkWord}
                aria-describedby="trademarkWordTooltip"
                type="text"
              />
            </Form.Group>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Row className="align-items-center pl-3">
                    <Form.Label className="mb-0" htmlFor="applicationNumber">
                      {translate("trademark.portlet.application.number")}
                    </Form.Label>
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_applicationNumber"
                      )}
                      id="applicationNumberTooltip"
                    />
                  </Row>
                  <Form.Control
                    className={
                      validApplicationNumber
                        ? "border border-dark"
                        : "is-invalid"
                    }
                    aria-describedby="applicationNumberTooltip"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    onKeyDown={(event) => keyPressHandler(event)}
                    id="applicationNumber"
                    name="applicationNumber"
                    value={fields.applicationNumber}
                    data-cy="application_number"
                  />
                  {validApplicationNumber ? null : (
                    <div className="invalid-feedback" role="alert">
                      {translate("trademark_application_number_warning")}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Row className="align-items-center pl-3">
                    <Form.Label className="mb-0" htmlFor="registrationNumber">
                      {translate("trademark.portlet.register.number")}
                    </Form.Label>
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_registrationNumber"
                      )}
                      id="registrationNumberTooltip"
                      placement="bottom"
                    />
                  </Row>
                  <Form.Control
                    className={
                      validRegistrationNumber
                        ? "border border-dark"
                        : "is-invalid"
                    }
                    aria-describedby="registrationNumberTooltip"
                    id="registrationNumber"
                    name="registrationNumber"
                    value={fields.registrationNumber}
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    onKeyDown={(event) => keyPressHandler(event)}
                    type="text"
                    data-cy="registration_number"
                  />
                  {validRegistrationNumber ? null : (
                    <div className="invalid-feedback" role="alert">
                      {translate("trademark_register_number_warning")}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label className="mb-0" htmlFor="filingNumber">
                  {translate("trademark_acceptance_reference")}
                </Form.Label>
                <ToolTip
                  text={translate("trademark_infotext_searchForm_filingNumber")}
                  id="filingNumberTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validFilingNumber ? "border border-dark" : "is-invalid"
                }
                id="filingNumber"
                name="filingNumber"
                value={fields.filingNumber}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                aria-describedby="filingNumberTooltip"
                type="text"
                data-cy="filing_number"
              />
              {validFilingNumber ? null : (
                <div className="invalid-feedback" role="alert">
                  {translate("trademark_filing_number_warning")}
                </div>
              )}
            </Form.Group>

            <Form.Group>
              <fieldset aria-describedby="dossierStatusTooltip">
                <legend className="prh-legend">
                  <Col xs={12}>
                    <Row className="align-items-center">
                      {translate("trademark.portlet.current.status")}
                      <ToolTip
                        text={translate(
                          "trademark_infotext_searchForm_dossierStatus"
                        )}
                        id="dossierStatusTooltip"
                      />
                    </Row>
                  </Col>
                </legend>
                <Form.Check
                  type="radio"
                  name="dossierStatus"
                  id="dossierStatusRegistered"
                  value="registered"
                  label={translate("trademark.portlet.preselect.status.active")}
                  onChange={handleChange}
                  checked={radioValue === "registered"}
                />

                <Form.Check
                  type="radio"
                  name="dossierStatus"
                  id="dossierStatusAll"
                  value="all"
                  label={translate("trademark.portlet.preselect.status.all")}
                  onChange={handleChange}
                  checked={radioValue === "all"}
                />
              </fieldset>
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label className="mb-0" htmlFor="trademarkType">
                  {translate("trademark.portlet.mark.kind")}
                </Form.Label>
                <ToolTip
                  text={translate("trademark_infotext_type")}
                  id="trademarkTypeTooltip"
                />
              </Row>
              <Form.Control
                className="border border-dark"
                aria-describedby="trademarkTypeTooltip"
                id="trademarkType"
                name="trademarkType"
                value={translate("trademarkType_infotext")}
                disabled
                type="text"
              />
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label className="mb-0" htmlFor="applicantName">
                  {translate("trademark.portlet.applicant")}
                </Form.Label>
                <ToolTip
                  text={translate(
                    "trademark_infotext_searchForm_applicantName"
                  )}
                  id="applicantNameTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validApplicantName ? "border border-dark" : "is-invalid"
                }
                aria-describedby="applicantNameTooltip"
                id="applicantName"
                name="applicantName"
                value={fields.applicantName}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
              />
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label className="mb-0" htmlFor="representativeName">
                  {translate("trademark.portlet.representative")}
                </Form.Label>
                <ToolTip
                  text={translate(
                    "trademark_infotext_searchForm_representativeName"
                  )}
                  id="representativeNameTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validRepresentativeName ? "border border-dark" : "is-invalid"
                }
                aria-describedby="representativeNameTooltip"
                id="representativeName"
                name="representativeName"
                value={fields.representativeName}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
              />
            </Form.Group>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label className="mb-0" htmlFor="businessID">
                  {translate("search_business_id")}
                </Form.Label>
                <ToolTip
                  text={translate("trademark_infotext_searchForm_businessID")}
                  id="businessIDTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validBusinessID ? "border border-dark" : "is-invalid"
                }
                aria-describedby="businessIDTooltip"
                id="businessID"
                name="businessID"
                value={fields.businessID}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
                data-cy="business_id"
              />
              {validBusinessID ? null : (
                <div className="invalid-feedback" role="alert">
                  {translate("trademark_businessID_warning")}
                </div>
              )}
            </Form.Group>

            <fieldset className="prh-fieldset">
              <legend className="prh-legend">
                <Col xs={12}>
                  <Row className="align-items-center">
                    {translate("trademark.portlet.application.date")}
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_applicationStartDate"
                      )}
                      id="applicationDateTooltip"
                    />
                  </Row>
                </Col>
              </legend>

              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="applicationStartDate"
                    >
                      {translate("search_startdate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="applicationDateTooltip"
                      id="applicationStartDate"
                      name="applicationStartDate"
                      data_cy="applicationStartDate"
                      value={
                        fields.applicationStartDate
                          ? new Date(fields.applicationStartDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    ></DatePick>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="applicationEndDate"
                    >
                      {translate("search_enddate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="applicationDateTooltip"
                      id="applicationEndDate"
                      name="applicationEndDate"
                      data_cy="applicationEndDate"
                      value={
                        fields.applicationEndDate
                          ? new Date(fields.applicationEndDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            <fieldset className="prh-fieldset">
              <legend className="prh-legend">
                <Col xs={12}>
                  <Row className="align-items-center">
                    {translate("trademark.portlet.registration.date")}
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_registrationStartDate"
                      )}
                      id="registrationDateTooltip"
                    />
                  </Row>
                </Col>
              </legend>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="registrationStartDate"
                    >
                      {translate("search_startdate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="registrationDateTooltip"
                      id="registrationStartDate"
                      name="registrationStartDate"
                      value={
                        fields.registrationStartDate
                          ? new Date(fields.registrationStartDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="registrationEndDate"
                    >
                      {translate("search_enddate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="registrationDateTooltip"
                      id="registrationEndDate"
                      name="registrationEndDate"
                      value={
                        fields.registrationEndDate
                          ? new Date(fields.registrationEndDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            <fieldset className="prh-fieldset">
              <legend className="prh-legend">
                <Col xs={12}>
                  <Row className="align-items-center">
                    {translate("trademark.portlet.announcement.date")}
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_oppositionPeriodStartStartDate"
                      )}
                      id="oppositionPeriodStartDateTooltip"
                    />
                  </Row>
                </Col>
              </legend>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="oppositionPeriodStartStartDate"
                    >
                      {translate("search_startdate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="oppositionPeriodStartDateTooltip"
                      id="oppositionPeriodStartStartDate"
                      name="oppositionPeriodStartStartDate"
                      value={
                        fields.oppositionPeriodStartStartDate
                          ? new Date(fields.oppositionPeriodStartStartDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="oppositionPeriodStartEndDate"
                    >
                      {translate("search_enddate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="oppositionPeriodStartDateTooltip"
                      id="oppositionPeriodStartEndDate"
                      name="oppositionPeriodStartEndDate"
                      value={
                        fields.oppositionPeriodStartEndDate
                          ? new Date(fields.oppositionPeriodStartEndDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            <fieldset className="prh-fieldset">
              <legend className="prh-legend">
                <Col xs={12}>
                  <Row className="align-items-center">
                    {translate("trademark.portlet.expiry.date")}
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_expirationStartDate"
                      )}
                      id="expirationDateTooltip"
                    />
                  </Row>
                </Col>
              </legend>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="expirationStartDate"
                    >
                      {translate("search_startdate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="expirationDateTooltip"
                      id="expirationStartDate"
                      name="expirationStartDate"
                      value={
                        fields.expirationStartDate
                          ? new Date(fields.expirationStartDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="expirationEndDate"
                    >
                      {translate("search_enddate")}
                    </Form.Label>
                    <DatePick
                      aria-describedby="expirationDateTooltip"
                      id="expirationEndDate"
                      name="expirationEndDate"
                      value={
                        fields.expirationEndDate
                          ? new Date(fields.expirationEndDate)
                          : ""
                      }
                      handleChange={handleDateChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>

            <Row>
              <Col xs={12}>
                <Form.Label>
                  {translate("trademark.portlet.good.and.service.class")}
                </Form.Label>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Row className="align-items-center pl-3">
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      htmlFor="goodsAndServicesClassNumber"
                    >
                      {translate("trademark.portlet.class.number")}
                    </Form.Label>
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_goodsAndServicesClassNumber"
                      )}
                      id="goodsAndServicesClassNumberTooltip"
                      placement="bottom"
                    />
                  </Row>
                  <Form.Control
                    className={
                      validGoodsAndServicesClassNumber
                        ? "border border-dark"
                        : "is-invalid"
                    }
                    aria-describedby="goodsAndServicesClassNumberTooltip"
                    id="goodsAndServicesClassNumber"
                    name="goodsAndServicesClassNumber"
                    value={fields.goodsAndServicesClassNumber}
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    onKeyDown={(event) => keyPressHandler(event)}
                    type="text"
                    data-cy="goods_and_services_class"
                  />
                  {validGoodsAndServicesClassNumber ? null : (
                    <div className="invalid-feedback" role="alert">
                      {translate("trademark_class_number_warning")}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Row className="align-items-center pl-3">
                    <Form.Label
                      style={{ fontSize: "13.6px" }}
                      className="mb-0"
                      htmlFor="goodsAndServicesTerm"
                    >
                      {translate("trademark.portlet.term")}
                    </Form.Label>
                    <ToolTip
                      text={translate(
                        "trademark_infotext_searchForm_goodsAndServicesTerm"
                      )}
                      id="goodsAndServicesTermTooltip"
                    />
                  </Row>
                  <Form.Control
                    className={
                      validGoodsAndServicesTerm
                        ? "border border-dark"
                        : "is-invalid"
                    }
                    aria-describedby="goodsAndServicesTermTooltip"
                    id="goodsAndServicesTerm"
                    name="goodsAndServicesTerm"
                    value={fields.goodsAndServicesTerm}
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    onKeyDown={(event) => keyPressHandler(event)}
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Row className="align-items-center pl-3">
                <Form.Label htmlFor="viennaClass">
                  {translate("trademark.portlet.vienna.classification")}
                </Form.Label>
                <ToolTip
                  text={translate("trademark_infotext_searchForm_viennaClass")}
                  id="viennaClassTooltip"
                />
              </Row>
              <Form.Control
                className={
                  validViennaClass ? "border border-dark" : "is-invalid"
                }
                aria-describedby="viennaClassTooltip"
                id="viennaClass"
                name="viennaClass"
                value={fields.viennaClass}
                onChange={handleChange}
                onBlur={handleFocusOut}
                onKeyDown={(event) => keyPressHandler(event)}
                type="text"
                data-cy="vienna_class"
              />
              {validViennaClass ? null : (
                <div className="invalid-feedback" role="alert">
                  {translate("trademark_vienna_class_warning")}
                </div>
              )}
            </Form.Group>
            <Row className="pb-4">
              <Col xs={6}>
                <Button block type="submit" data-cy="submit">
                  {translate("trademark.portlet.search.button")}
                </Button>
              </Col>
              <Col xs={6}>
                <Button block onClick={handleReset} data-cy="reset">
                  {translate("trademark.portlet.clear.button")}
                </Button>
              </Col>
            </Row>
          </Form>
        </section>
      </div>
    </div>
  );
};

export default withRouter(SearchForm);
