import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import DataField from "../../common/DataField";

const TargetGroupInfo = props => {
  const { translate } = useContext(I18nContext);

  return (
    <div>
      <h3>{translate("trademark.portlet.target.group")}</h3>
      {!props.data ? (
        <Row>
        <Col xs={6} md={4}>
            <DataField
            title={translate("trademark.portlet.target.group")}
            value={translate("trademark.portlet.no.target.group")}
            />
        </Col>
    </Row>
      ) : (
        <Row>
            <Col xs={6} md={4}>
                <DataField
                title={translate("trademark.portlet.target.group")}
                value={props.data}
                />
            </Col>
        </Row>
      )}
    </div>
  );
};

export default TargetGroupInfo;
