import React, { useContext, useEffect } from "react";
import { I18nContext } from "../i18n";
import Container from "react-bootstrap/Container";
import MobileNavbar from "../components/header/MobileNavbar";
import ExpandedNavbar from "../components/header/ExpandedNavbar";
import TmrDetails from "../components/TmrDetails/TmrDetails";
import { withRouter } from "react-router-dom";
import "../scss/styles/app.scss";
import UseWindowSize from "../customHooks/useWindowSize";
import { bootStrapGrid } from "../constants/constants";
import Footer from "../components/common/Footer";

const TmrDetailsPage = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  document.title = translate("trademark.portlet.tmr.details") + " | PRH";

  useEffect(() => {
    props.pathParams.updateLanguage();
    props.setActiveLink("TMR");
  }, []);

  return (
    <>
      <Container fluid="true" className="flex-wrapper m-0 p-0">
        <div>
          <div id="skip">
            <a href="#tmr_details_content">
              {translate("jumplink_to_content")}
            </a>
          </div>
          {size.width < bootStrapGrid.md ? (
            <MobileNavbar pathParams={props.pathParams} />
          ) : (
            <ExpandedNavbar
              activeLink={props.activeLink}
              pathParams={props.pathParams}
            />
          )}
          <main id="tmr_details_content" tabIndex="-1">
            <TmrDetails
              fetchTrademark={props.fetchTrademark}
              trademark={props.trademark}
            />
          </main>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default withRouter(TmrDetailsPage);
