import React, { useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import Pagination from "../common/Pagination";
import { formatDate, formatTrademarkPath } from "../../helpers/helpers";

const TableView = (props) => {
  const { translate } = useContext(I18nContext);
  const [pageOfItems, setPageOfItems] = useState([]);

  const onChangePage = (currentPage, pageOfItems) => {
    setPageOfItems(pageOfItems);
    props.onChangePage(currentPage);
    window.scrollTo(0, 0);
  };

  const findOldestDate = (list) => {
    return (
      list.sort((a, b) =>
        a.priorityDate && b.priorityDate
          ? a.priorityDate.localeCompare(b.priorityDate)
          : 0
      )[0] || {}
    );
  };

  const tmrHeaders = (
    <thead>
      <tr>
        <th aria-colindex="1">{translate("trademark.portlet.number")}</th>
        <th aria-colindex="2" className="text-center">
          {translate("trademark_tableView_image_and_word")}
        </th>
        <th aria-colindex="3">{translate("trademark.portlet.classes")}</th>
        <th aria-colindex="4">{translate("trademark.portlet.target.group")}</th>
        <th aria-colindex="5">
          {translate("trademark_tableView_application_and_registrationdate")}
        </th>
        <th aria-colindex="6">
          {translate("trademark.portlet.current.status")}
        </th>
        <th aria-colindex="7">
          {translate("trademark.portlet.applicant.registered")}
        </th>
      </tr>
    </thead>
  );

  const goToTmrDetails = ( props, item ) => {
    props.history.push( props.pathParams.buildPath( 'tmr/' + item.applicationNumber ));
  };

  const tmrBody = (
    <tbody>
      {pageOfItems.map((item, index) => (
        <tr
          className="prh-table-row"
          key={item.dossierId}
          tabIndex="0"
          aria-rowindex={index + 1}
          role="row"
          onClick={() => goToTmrDetails( props, item )}
          onKeyUp={(e) =>
            e.keyCode === 13
              ? goToTmrDetails( props, item )
              : null
          }
          title={item.trademarkWord}
        >
          <td aria-colindex="1" data-label="Hakemusnumero" role="cell">
            <div>
              <Link
                className="table-link p-0"
                to={ props.pathParams.buildPath( `tmr/` + item.applicationNumber )}
                tabIndex="-1"
              >
                {item.applicationNumber || "-"}
              </Link>
            </div>
          </td>
          <td
            aria-colindex="2"
            data-label="Merkin kuva ja -sana"
            role="cell"
            className="text-center"
          >
            {item.trademarkHasImage ? (
              <div>
                <Link
                  className="table-link p-0"
                  to={ props.pathParams.buildPath( `tmr/` + item.applicationNumber )}
                  tabIndex="-1"
                >
                  <img
                    data-cy="image"
                    className="img-fluid"
                    src={item.thumbnailUrl}
                    alt={translate("trademark_image")}
                  />
                </Link>
              </div>
            ) : null}

            <div>{item.trademarkWord}</div>
          </td>
          <td aria-colindex="3" data-label="Luokkanumero" role="cell">
            <div>
              {item.goodsAndServices.map((item) => item.classNumber).join(" ")}
            </div>
          </td>
          <td aria-colindex="4" data-label="Kohderyhmä" role="cell">
            <div>{item.targetGroup || "-"}</div>
          </td>
          <td
            aria-colindex="5"
            data-label="Hakemis- ja hyväksymispvm"
            role="cell"
          >
            <div>{formatDate(item.applicationDate) || "-"}</div>
            <div>{formatDate(item.registrationDate) || "-"}</div>
          </td>
          <td aria-colindex="6" data-label="Nykyinen tila" role="cell">
            <div>
              {translate(
                "trademark.markstatus." + item.dossierStatus.toLowerCase()
              )}
            </div>
          </td>
          <td aria-colindex="7" data-label="Haltia" role="cell">
            <div>{item.applicants.map((item) => item.name).join(" ")}</div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const trademarkHeaders = (
    <thead>
      <tr>
        <th aria-colindex="1">
          {translate("trademark_tableView_application_and_register_number")}
        </th>
        <th aria-colindex="2" className="text-center">
          {translate("trademark_tableView_image_and_word")}
        </th>
        <th aria-colindex="3">{translate("trademark.portlet.classes")}</th>
        <th aria-colindex="4">
          {translate("trademark_tableView_periority_and_applicationdate")}
        </th>
        <th aria-colindex="5">
          {translate("trademark_tableView_registration_and_expirationdate")}
        </th>
        <th aria-colindex="6">
          {translate("trademark.portlet.current.status")}
        </th>
        <th aria-colindex="7">
          {translate("trademark.portlet.applicant.registered")}
        </th>
      </tr>
    </thead>
  );

  const goToTrademarkDetails = ( props, item ) => {
    props.history.push( props.pathParams.buildPath( 'trademark/' + formatTrademarkPath( item )));
  };

  const trademarkBody = (
    <tbody>
      {pageOfItems.map((item, index) => (
        <tr
          className="prh-table-row"
          key={item.dossierId}
          tabIndex="0"
          aria-rowindex={index + 1}
          role="row"
          onClick={() => goToTrademarkDetails( props, item )}
          onKeyUp={(e) =>
            e.keyCode === 13
              ? goToTrademarkDetails( props, item )
              : null
          }
          title={item.trademarkWord}
        >
          <td aria-colindex="1" data-label="Hakemusnumero" role="cell">
            <div>
              <Link
                className="table-link p-0"
                to={ props.pathParams.buildPath( "trademark/" + formatTrademarkPath(item) )}
                tabIndex="-1"
              >
                {item.applicationNumber || "-"}
              </Link>
            </div>
            <div>{item.registrationNumber || "-"}</div>
          </td>
          <td
            aria-colindex="2"
            data-label="Merkin kuva ja -sana"
            role="cell"
            className="text-center"
          >
            {item.trademarkHasImage ? (
              <div>
                <Link
                  className="table-link p-0"
                  to={ props.pathParams.buildPath( "trademark/" + formatTrademarkPath(item) )}
                  tabIndex="-1"
                >
                  <img
                    data-cy="image"
                    className="img-fluid"
                    src={item.thumbnailUrl}
                    alt={translate("trademark_image")}
                  />
                </Link>
              </div>
            ) : null}

            <div>{item.trademarkWord}</div>
          </td>
          <td aria-colindex="3" data-label="Luokkanumero" role="cell">
            <div>
              {item.goodsAndServices.map((item) => item.classNumber).join(" ")}
            </div>
          </td>
          <td
            aria-colindex="4"
            data-label="Etuoikeus ja -hakemispvm"
            role="cell"
          >
            <div>
              <span>
                {formatDate(findOldestDate(item.priorities).priorityDate) ||
                  "-"}
              </span>
            </div>
            <div>{formatDate(item.applicationDate) || "-"}</div>
          </td>
          <td
            aria-colindex="5"
            data-label="Rekisteröinti- ja erääntymispvm"
            role="cell"
          >
            <div>{formatDate(item.registrationDate) || "-"}</div>
            <div>{formatDate(item.expirationDate) || "-"}</div>
          </td>
          <td aria-colindex="6" data-label="Nykyinen tila" role="cell">
            <div>
              {translate(
                "trademark.markstatus." + item.dossierStatus.toLowerCase()
              )}
            </div>
          </td>
          <td aria-colindex="7" data-label="Haltia" role="cell">
            <div>{item.applicants.map((item) => item.name).join(" ")}</div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <Container>
      <div className="table-responsive mt-2">
        <table
          id=""
          role="table"
          aria-colcount="4"
          aria-rowcount="-1"
          className="table table-hover table-stacked-md prh-table-view"
        >
          {props.path === "tmr" ? tmrHeaders : trademarkHeaders}
          {props.path === "tmr" ? tmrBody : trademarkBody}
        </table>
      </div>
      <Row>
        <Col style={{ paddingRight: "0px" }}>
          <Pagination
            items={props.data.results}
            onChangePage={onChangePage}
            pagesOnBar={10}
            pageSize={20}
            currentPage={props.currentPage}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(TableView);
