import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Icons from "../../scss/prh-icon-sprite.svg";
import { I18nContext } from "../../i18n";

const Accordion = (props) => {
  const { translate } = useContext(I18nContext);
  const [expanded, setExpanded] = useState(props.expand);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(props.expand || false);
  }, [props.expand]);

  return (
    <div className="mb-2">
      <h4 className="mb-0">
        <button
          type="button"
          className="btn btn-secondary mb-0"
          style={{ width: "100%", textAlign: "left" }}
          onClick={handleClick}
          role="tab"
          id={"accordion-btn-" + props.id}
          aria-expanded={expanded}
          aria-controls={props.id}
          aria-label={props.title}
        >
          <Row>
            <Col xs={11} style={{ textAlign: "left", padding: "0px" }}>
              <span>{props.title}</span>
              {props.accepted !== undefined && props.accepted === false && (
                <span>
                  ({translate("trademark.portlet.class.not.confirmed")})
                </span>
              )}
            </Col>
            <Col xs={1} style={{ textAlign: "right", padding: "0px" }}>
              <svg alt="" className="prh-icon">
                <use xlinkHref={`${Icons}#chevron-down`}></use>)
              </svg>
            </Col>
          </Row>
        </button>
      </h4>
      {expanded ? (
        <div
          role="tabpanel"
          aria-labelledby={"accordion-btn-" + props.id}
          id={props.id}
          style={{ border: "1px solid gray", borderColor: "LightGray" }}
          className={"d-print-none"}
        >
          {props.content}
        </div>
      ) : null}
      <div
        role="tabpanel"
        style={{ border: "1px solid gray", borderColor: "LightGray" }}
        className={"d-none d-print-block"}
      >
        {props.content}
      </div>
    </div>
  );
};

export default Accordion;
