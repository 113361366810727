import React, { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { I18nContext } from "../../i18n";
import PersonInfo from "../common/PersonInfo";
import CorrespondenceInfo from "../common/CorrespondenceInfo";
import BasicInfo from "./RBasicInfo";
import DetailedInfo from "./RDetailedInfo";
import LicenseInfo from "./RLicenseInfo";
import PledgeInfo from "./RPledgeInfo";
import MobileNavbar from "../../components/header/MobileNavbar";
import ExpandedNavbar from "../../components/header/ExpandedNavbar";
import UseWindowSize from "../../customHooks/useWindowSize";
import { formatDate } from "../../helpers/helpers";
import { bootStrapGrid } from "../../constants/constants";
import PageNotFound from "../errors/PageNotFound";


const RApplication = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  document.title = translate("application_title_recordal") + " | PRH";

  const data = props.recordal.data;

  const basicInfo = {
    applicationNumber: data.applicationNumber,
    recordalType: data.recordalType,
    applicationDate: formatDate(data.applicationDate),
    dossierStatus: data.dossierStatus,
    dossierStatusDate: formatDate(data.dossierStatusDate),
  };

  const detailedInfo = {
    relatedTrademarks: data.relatedTrademarks,
  };

  const licenseData = {
    license: data.licence,
  };

  const pledgeData = {
    pledge: data.pledge,
  };

  useEffect(() => {
    props.pathParams.updateLanguage();
    props.fetchRecordal(
      "trademark/recordal/" + props.match.params.applicationNumber
    );
  }, []);

  if (data.length < 1) {
    if(props.recordal.error)
    return (<PageNotFound title="trademark_not_found" description="trademark_not_found_description"/>);

    return null;
  }

  return (
    <div>
      <div id="skip">
        <a href="#r_content">{translate("jumplink_to_content")}</a>
      </div>
      {size.width < bootStrapGrid.md ? (
        <MobileNavbar pathParams={props.pathParams} />
      ) : (
        <ExpandedNavbar pathParams={props.pathParams} />
      )}
      <main id="r_content" tabIndex="-1">
        <Container className={"mt-4 mb-4"}>
          <BasicInfo data={basicInfo} />
          <DetailedInfo data={detailedInfo} pathParams={props.pathParams} />
          {data.licence && <LicenseInfo data={licenseData} />}
          {data.pledge && <PledgeInfo data={pledgeData} />}
          <PersonInfo
            title={translate("k_application_applicant")}
            persons={data.applicants}
          />
          <PersonInfo
            title={translate("trademark.portlet.representative")}
            persons={data.representatives}
          />
          <CorrespondenceInfo data={data.correspondence} />
        </Container>
      </main>
    </div>
  );
};

export default withRouter(RApplication);
