import React, { useContext, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { I18nContext } from "../../../i18n";
import UseWindowSize from "../../../customHooks/useWindowSize";
import DataField from "../../common/DataField";
import { bootStrapGrid } from "../../../constants/constants";
import no_image from "../../../scss/styles/images/no-image.svg";


const TmrBasicInfo = (props) => {
  const { translate } = useContext(I18nContext);
  const size = UseWindowSize();
  const [showModal, setShowModal] = useState(false);
  const fields = props.data;
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  const trademarkImage = () => {
    return fields.imageSpecification.imageUrl ? (
      <button className="p-0" onClick={handleOpen} style={{ border: "none" }}>
        <img
          data-cy="image"
          className="img-fluid"
          src={fields.imageSpecification.largeThumbnailUrl}
          alt={translate("trademark_image_open_large")}
        />
      </button>
    ) : (
      <div className="text-center">
        <img
          className="img-fluid prh-no-image"
          src={no_image}
          alt=""
          data-cy="no_image"
        />
        <br />
        <span data-cy="no_image_text">{translate("trademark_no_image")}</span>
      </div>
    );
  };

  const applicationNumber = (
    <DataField
      title={translate("trademark.portlet.number")}
      value={fields.applicationNumber || "-"}
    />
  );

  const markWord = (
    <DataField
      title={translate("trademark.portlet.mark.word")}
      value={fields.trademarkWord || "-"}
    />
  );

  const classes = (
    <DataField
      title={translate("trademark.portlet.result.header.classes")}
      value={
        fields.goodsAndServices.classes.length < 1
          ? "-"
          : fields.goodsAndServices.classes
              .map((item) => item.classNumber)
              .join(" ")
      }
    />
  );

  const status = (
    <DataField
      title={translate("trademark.portlet.result.header.status")}
      value={
        translate(
          "trademark.markstatus." + fields.dossierStatus.toLowerCase()
        ) || "-"
      }
    />
  );

  const applicationDate = (
    <DataField
      title={translate("trademark.portlet.application.date")}
      value={fields.applicationDate || "-"}
    />
  );

  const registerDate = (
    <DataField
      title={translate("trademark.portlet.approval.date")}
      value={fields.registrationDate || "-"}
    />
  );

  const expiryDate = (
    <DataField
      title={translate("trademark.portlet.expiry.date")}
      value={fields.expirationDate || "-"}
    />
  );

  const imageModal = (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <img
          data-cy="image"
          className="img-fluid pb-4"
          src={fields.imageSpecification.imageUrl}
          alt={translate("trademark_image")}
        />
      </Modal.Body>
    </Modal>
  );

  const mobileView = (
    <div>
      <Row>
        <Col xs={6}>{trademarkImage()}</Col>
        <Col xs={6}>
          <Row>
            <Col xs={12}>{applicationNumber}</Col>
          </Row>
          <Row>
            <Col xs={12}>{markWord}</Col>
          </Row>
          <Row>
            <Col xs={12}>{classes}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>{status}</Col>
      </Row>
      <Row>
        <Col xs={6}>{applicationDate}</Col>
        <Col xs={6}>{registerDate}</Col>
      </Row>
      <Row>
        <Col xs={6}>{expiryDate}</Col>
      </Row>
    </div>
  );

  const padView = (
    <div>
      <Row>
        <Col md={3}>{trademarkImage()}</Col>
        <Col md={9}>
          <Row>
            <Col md={4}>{applicationNumber}</Col>
            <Col md={4}>{markWord}</Col>
            <Col md={4}>{classes}</Col>
          </Row>
          <Row>
            <Col md={4}>{applicationDate}</Col>
            <Col md={4}>{registerDate}</Col>
            <Col md={4}>{expiryDate}</Col>
          </Row>
          <Row>
            <Col md={12}>{status}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );

  if (!props.data) {
    return null;
  }

  return (
    <div>
      <h3>{translate("trademark.portlet.basic.information")}</h3>
      {imageModal}
      {size.width < bootStrapGrid.sm ? mobileView : padView}
    </div>
  );
};

export default TmrBasicInfo;
