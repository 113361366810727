export const allDossierStatuses = [
  "Application_filed",
  "Registered_wait_publication",
  "Registered_opposition_period_running",
  "Registration_opposed",
  "Registered",
  "Expired",
  "Application_withdrawn",
  "Application_dismissed",
  "Application_rejected",
  "Appeal_pending",
  "Rev_inv_pending",
  "Registration_revoked_after_opposition",
  "Registration_cancelled_court_decision",
  "Registration_revoked",
  "Registration_invalidated",
  "Registration_revoked_and_invalidated",
  "Ended_withdrawn",
  "Ended",
  "Application_rejected_due_to_opposition",
  "IR_pending_in_Finland",
  "IR_accepted_as_valid_in_Finland_waiting_for_publication",
  "IR_accepted_as_valid_in_Finland_period_for_filing_an_opposition_running",
  "IR_accepted_as_valid_in_Finland_opposition_proceedings_under_way",
  "IR_valid_in_Finland",
  "IR_not_valid_in_Finland_not_renewed",
  "IR_not_valid_in_Finland_refused",
  "IR_canceled",
  "IR_rejected",
  "IR_appeal_pending",
  "IR_rev_inv_pending",
  "IR_not_valid_in_Finland_rejected_on_account_of_an_opposition",
  "IR_removed_from_registration_on_account_of_court_order",
  "IR_not_valid_in_Finland_revoked",
  "IR_not_valid_in_Finland_invalidated",
  "IR_not_valid_in_Finland_revoked_and_invalidated",
  "IR_not_valid_in_Finland_dismissed"
];

export const registeredDossierStatuses = [
  "Application_filed",
  "Registered_wait_publication",
  "Registered_opposition_period_running",
  "Registration_opposed",
  "Registered",
  "Appeal_pending",
  "Rev_inv_pending",
  "IR_pending_in_Finland",
  "IR_accepted_as_valid_in_Finland_waiting_for_publication",
  "IR_accepted_as_valid_in_Finland_period_for_filing_an_opposition_running",
  "IR_accepted_as_valid_in_Finland_opposition_proceedings_under_way",
  "IR_valid_in_Finland",
  "IR_appeal_pending",
  "IR_rev_inv_pending"
];

export const allTmrDossierStatuses = [
  "TMR_Pending",
  "TMR_Admitted",
  "TMR_Removed_not_renewed"
];
