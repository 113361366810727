import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "../../common/Accordion";
import { I18nContext } from "../../../i18n";
import { formatDate } from "../../../helpers/helpers";
import { Link, withRouter } from "react-router-dom";

const RelatedDossierInfo = (props) => {
  const { translate } = useContext(I18nContext);

  const accordionContent = (item, path) => {
    return (
      <div className="pb-3 pt-3">
        <Row className="row pl-4">
          <Col xs={6}>{translate("application_or_opposition_number")}</Col>
          <Col xs={6}>
            {item.applicationNumber.length < 3 ||
            item.dossierType === "TrademarkAppeal" ? (
              item.applicationNumber
            ) : (
              <Link
                className="p-0"
                to={{
                  pathname: props.pathParams.buildPath( path + item.applicationNumber )
                }}
              >
                {item.applicationNumber}
              </Link>
            )}
          </Col>
        </Row>
        <Row className="row pl-4">
          <Col xs={6}>{translate("trademark.portlet.events.filing.date")}</Col>
          <Col xs={6}>{formatDate(item.applicationDate) || "-"}</Col>
        </Row>
        <Row className="row pl-4">
          <Col xs={6}>{translate("trademark.portlet.events.status.date")}</Col>
          <Col xs={6}>{formatDate(item.dossierStatusDate) || "-"}</Col>
        </Row>
        <Row className="row pl-4">
          <Col xs={6}>{translate("trademark.portlet.events.status")}</Col>
          <Col xs={6}>
            {translate(
              "trademark.event.status." +
                item.dossierType.toLowerCase() +
                "." +
                item.dossierStatus.toLowerCase()
            ) || "-"}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="pb-4">
      <h3>{translate("trademark.portlet.events")}</h3>
      {props.data.length < 1 ? (
        <span>-</span>
      ) : (
        props.data.map((item) => {
          let path = "";
          let translationCode = "";
          if (item.dossierType === "TrademarkRecordal") {
            path = "recordal/";
            translationCode = "trademark.event.type.trademarkrecordal.";
          }
          if (item.dossierType === "TrademarkInternationalApp") {
            path = "international/";
          }
          if (item.dossierType === "TrademarkOpposition") {
            path = "opposition/";
            translationCode = "trademark.event.type.trademarkopposition.";
          }
          return (
            <Accordion
              key={item.ordinal}
              title={
                item.dossierSubtype
                  ? translate(
                      translationCode + item.dossierSubtype.toLowerCase()
                    )
                  : translate(
                      "trademark.event.type." + item.dossierType.toLowerCase()
                    )
              }
              content={accordionContent(item, path)}
            />
          );
        })
      )}
    </div>
  );
};

export default withRouter(RelatedDossierInfo);
